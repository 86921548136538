// import React, { Component } from "react";
// import { Button, Form, Input, Select, DatePicker, TimePicker } from "antd";
// import moment from "moment";
// import api from  '../api/arretprod';
// import api_production from  '../api/prod';
// import Notifiyer from '../notification/index';

// import {
//   PlusSquareOutlined,
//   PlusCircleOutlined
// } from '@ant-design/icons';
// import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

// import { useState } from "react";
// import { Modal } from "antd";
// import { Row, Col } from "antd";
// const { TextArea } = Input;


// const StopProdModel = ({ onSubmit,isCreated = true, initialFormData = {
//   codeEntite: "Usine_1",
//   dateProduction: null,
//   dateDemarrage: moment().format('HH:mm:ss'), 
//   dateArret: moment().format('HH:mm:ss'),
//   motifArret: "" 

// } }) => {

   
//   const [formData, setFormData] = useState(initialFormData);

//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [submittedMessage, setsubmittedMessage] = useState('');
  

//   const handleChange = (name, value) => {
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSubmit = () => {
//      if (!formData.dateDemarrage || !formData.dateArret || !formData.motifArret) {
//       setErrorMessage('Please fill out all required fields.');
//       return;
//     }
//     try{
//       api_production.findProd(formData)
//       .then(prod => {
//           console.log("prod: ", prod);
//          if (prod.data != "noprod") {
//               // Proceed with the logic when production is found
//               console.log(formData);
//               if(isCreated){
//                 console.log("addProdArret : ",formData)
//                 api.addProdArret(formData);

//               }else {
//                 api.updateProdArret(formData);
//                 console.log("updateProdArret : ",formData)

//               }
//               setFormData(initialFormData);
//               Notifiyer();
//               handleCancel();
//                  setsubmittedMessage('Données enregistrées.');
//       setTimeout(() => {
//       onSubmit();
//       }, 1000);
//       setTimeout(() => {
//           setsubmittedMessage('');
//         }, 3000);

//           } else {
//             console.error("Error finding production:");

//             setErrorMessage("Production needs to be created first.");
//             setTimeout(() => {
//               setErrorMessage('');
//             }, 3000);
//               // Handle other status codes if needed
//           }
//       })
//       .catch(error => {
//           console.error("Error finding production:", error);
//       });
  
  
    
//       //onSubmit();
//     } catch (error) {
//       console.error('Failed to submit form data:', error);
//       setErrorMessage('Failed to submit form data. Please try again.',error);
//     }

//   };

//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };
//   const handleTimeChange = (time, timeString) => {
//     console.log("Selected Time: ", timeString);
//   };



//   return (
//     <>
   
     
//       {isCreated ? (
//            <Button type="primary" onClick={showModal}>< PlusSquareOutlined style={{ fontSize: '17px' }} />Arrêt de prod
//            </Button>
//       ) : (
//         <Button type="primary" icon={<EditOutlined />} size="small" onClick={showModal}></Button>
//       )}
//       <Modal
//         title="Arrêter la production"
//         open={isModalOpen}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         footer={null}
//         style={{ top: 20 }}
//       >
//              {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
//       {submittedMessage && <p style={{ color: 'green' }}>{submittedMessage}</p>}


//         <Form layout="horizontal" name="basic" autoComplete="off" 
//          onFinish={handleSubmit}
//          initialValues={{
//           dateDemarrage: moment(formData.dateDemarrage, 'HH:mm:ss'), // Provide initial value for the 'name' field,
//           dateArret : moment(formData.dateArret, 'HH:mm:ss')
//         }}
//         >
//           <Form.Item label="Entité de production:">
//             <Select
            
//             defaultValue={formData.codeEntite}
//             disabled={!isCreated}
//             onChange={(value) => handleChange('codeEntite', value)}
//             >
//               <Select.Option value="Usine_1">Usine_1</Select.Option>
//               <Select.Option value="Usine_2">Usine_2</Select.Option>
//               <Select.Option value="Broilleur">Broilleur</Select.Option>
//             </Select>
//           </Form.Item>

//           <Form.Item label="Date de production:">
//             <DatePicker style={{ width: "100%" }} 
//            defaultValue={formData.dateProduction ?   moment(formData.dateProduction,'YYYY-MM-DD') : null}
//            disabled={!isCreated}
//            onChange={(date) => handleChange('dateProduction', date)}
//            />
//           </Form.Item>

//           <Row gutter={[16, 16]}>
//             <Col xs={24} sm={24} md={12}>
//               <Form.Item label="Date Démarrage"
                 
//               >
//                 <TimePicker 

//                 name="dateDemarrage"   defaultValue={moment(formData.dateDemarrage, 'HH:mm:ss')}
//                   onChange={(time, timeString) => handleChange('dateDemarrage', timeString)}
//                      format="HH:mm:ss"
//             style={{ width: '100%' }}  
        
//             />
//               </Form.Item>
//             </Col>
//             <Col xs={24} sm={24} md={12}>
//               <Form.Item label="Date Arrêt" >
//                 <TimePicker 
//                  name="dateDemarrage"
//                  defaultValue={moment(formData.dateArret, 'HH:mm:ss')}
//                  onChange={(time, timeString) => handleChange('dateArret', timeString)}
//                    format="HH:mm:ss"
//                 style={{ width: '100%' }} 
               
//                 />
//               </Form.Item>
//             </Col>
//           </Row>

//           <Form.Item label="Motif d'arrêt"
     
//           >
//             <TextArea rows={4} placeholder="" 
//               value={formData.motifArret}
//               onChange={(e) => handleChange('motifArret', e.target.value)}
//               />
//           </Form.Item>

//           <Form.Item>
//             <Button type="primary" htmlType="submit">
//             Sauvegarder
//             </Button>
//           </Form.Item>
//         </Form>
//       </Modal>
//     </>
//   );
// };

// export default StopProdModel;
import React, { useState } from "react";
import { Button, Form, Input, Select, DatePicker, TimePicker, Modal, Row, Col } from "antd";
import moment from "moment";
import api from '../api/arretprod';
 import api_production from  '../api/prod';
import Notifiyer from '../notification/index';
import dayjs from 'dayjs';

import { PlusSquareOutlined, EditOutlined,PlusOutlined } from '@ant-design/icons';
const { TextArea } = Input;

const StopProdModel = ({ onSubmit, isCreated ,initialFormData  }) => {
  // initialFormData = {
  //   codeEntite: "Usine_1",
  //   dateProduction: null,
  //   dateDemarrage: moment().format('HH:mm:ss'),
  //   dateArret: moment().format('HH:mm:ss'),
  //   motifArret: "",
  //   stopId:"",
  //   prodId:""
  
  // }
  const [formData, setFormData] = useState(initialFormData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [submittedMessage, setsubmittedMessage] = useState('');
  const [form] = Form.useForm();
 console.log("update = "+ isCreated)
 console.log(initialFormData)

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
  //  form.validateFields().then(values => {
      try {
        api_production.findProd(initialFormData)
          .then(prod => {
            if (prod.data !== "noprod") {
              console.log("prod found")
              if (isCreated) {

                console.log("addProdArret")

                console.log(formData)
                console.log(formData)

                api.addProdArret(formData);
              } else {
                console.log("updateProdArret")

                api.updateProdArret(formData);
              }
              Notifiyer();
              handleCancel();
              setsubmittedMessage('Données enregistrées.');
              setTimeout(() => {
                onSubmit();
              }, 1000);
              setTimeout(() => {
                setsubmittedMessage('');
              }, 3000);
            } else {
              setErrorMessage("Production needs to be created first.");
              setTimeout(() => {
                setErrorMessage('');
              }, 3000);
            }
          })
          .catch(error => {
            console.error("Error finding production:", error);
          });
      } catch (error) {
        console.error('Failed to submit form data:', error);
        setErrorMessage('Failed to submit form data. Please try again.', error);
      }
    // }).catch(errorInfo => {
    //   console.log('Validation failed:', errorInfo);
    // });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  return (
    <>
      {isCreated ? (
        <Button type="primary" onClick={showModal} size="small" icon={<PlusOutlined  />}  >
        </Button>
      ) : (
        <Button type="primary" ghost icon={<EditOutlined />} size="small" onClick={showModal}></Button>
      )}
      <Modal
        title="Arrêter la production"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 20 }}
      >
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        <Form
          form={form}
          layout="horizontal"
          name="basic"
          autoComplete="off"
          onFinish={handleSubmit}
          initialValues={{
            codeEntite: initialFormData.codeEntite,
            dateProduction: initialFormData.dateProduction ? dayjs(initialFormData.dateProduction, 'YYYY-MM-DD') : null,
            dateDemarrage: initialFormData.dateDemarrage ? dayjs(initialFormData.dateDemarrage, 'HH:mm:ss'): null,
            dateArret: initialFormData.dateArret ? dayjs(initialFormData.dateArret, 'HH:mm:ss'): null,
            motifArret: initialFormData.motifArret
          }}
        >
          {/* <Form.Item label="Entité de production:" name="codeEntite">
            <Select
              defaultValue={formData.codeEntite}
              disabled={!isCreated}
              onChange={(value) => handleChange('codeEntite', value)}
            >
              <Select.Option value="Usine_1">Usine_1</Select.Option>
              <Select.Option value="Usine_2">Usine_2</Select.Option>
              <Select.Option value="Broilleur">Broilleur</Select.Option>
            </Select>
          </Form.Item> */}

          {/* <Form.Item label="Date de production:" name="dateProduction">
            <DatePicker
              style={{ width: "100%" }}
              disabled={!isCreated}
              onChange={(date) => handleChange('dateProduction', date)}
              placeholder="Sélectionnez une date"
            />
          </Form.Item> */}

          {/* <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12}> */}
              <Form.Item label="Heure début d'arrêt " name="dateDemarrage">
                <TimePicker
                  onChange={(time, timeString) => handleChange('dateDemarrage', timeString)}
                  format="HH:mm:ss"
                  style={{ width: '100%' }}
                  placeholder="Heure début d'arrêt"
                />
              </Form.Item>
            {/* </Col>
            <Col xs={24} sm={24} md={12}> */}
              <Form.Item label="Heure fin d'arrêt " name="dateArret">
                <TimePicker
                  onChange={(time, timeString) => handleChange('dateArret', timeString)}
                  format="HH:mm:ss"
                  style={{ width: '100%' }}
                  placeholder="Heure fin d'arrêt"
                />
              </Form.Item>
            {/* </Col>
          </Row> */}


          <Form.Item label="Motif d'arrêt" name="motifArret">
            <TextArea
              rows={4}
              placeholder=""
              value={formData.motifArret}
              onChange={(e) => handleChange('motifArret', e.target.value)}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Sauvegarder
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default StopProdModel;
