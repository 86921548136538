// import React, { useState } from 'react';
// import axios from 'axios';
// import moment from 'moment';
// import { DatePicker, Button, Modal } from 'antd';
// import API_URL from '../api/const';
// import {
//   CloudDownloadOutlined,
//   PlusCircleOutlined
// } from '@ant-design/icons';
// const { RangePicker } = DatePicker;

// const GeneralReport = ({ endpoint }) => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [value, setValue] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [reportName, setReportName] = useState('');

//   const showModal = () => {
//     setIsModalOpen(true);
//   };

//   const handleOk = () => {
//     setIsModalOpen(false);
//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);
//     // Clear input values when modal is closed
//     setValue(null);
//    // setReportName('');
//   };

//   const requestReport = () => {
//     setLoading(true);
//     setErrorMessage('');

//     if (!value || !value[0] || !value[1]) {
//       setErrorMessage('Please select a date range.');
//       setLoading(false);
//       return;
//     }

//     const formatDateToYearMonthDay = date => moment(date).format('YYYY-MM-DD');

//     axios.get(`${API_URL}${endpoint}/report`, {
//       params: {
//         startDate: formatDateToYearMonthDay(value[0].$d),
//         endDate: formatDateToYearMonthDay(value[1].$d)
//       },
//       responseType: 'blob'
//     })
//       .then(response => {
//         setLoading(false);
//        // const disposition = response.headers['content-disposition'];
//         //const match = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
//       //  const fileName = match && match[1] ? match[1].replace(/['"]/g, '') : 'report.pdf';

//        // console.log("fileName",fileName);
//         // Refactor report name
//         const today = moment().format('YYYY-MM-DD_HH-mm-ss');

//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download',`${endpoint}_report_${today}.pdf`);
//         document.body.appendChild(link);
//         link.click();
//       })
//       .catch(error => {
//         setLoading(false);
//         console.error('Error generating report: ', error);

//         if (error.response && error.response.status === 502) {
//           setErrorMessage('No data found in the date range.');
//         } else {
//           setErrorMessage('Error generating report. Please try again.');
//         }
//       });

//   };

//   return (
//     <>
//       <Button type="primary" onClick={showModal}>
//         Rapports
//       </Button>
//       <Modal
//         title=""
//         visible={isModalOpen} // changed 'open' to 'visible'
//         onOk={handleOk}
//         onCancel={handleCancel}
//         footer={null}
//         style={{ top: 20 }}
//       >

//         {errorMessage && <span style={{ color: 'red' }}>{errorMessage}</span>}

//         <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '23px' }}>
//           <RangePicker value={value} onChange={setValue} style={{ width: '100%' }} />
//           <Button loading={loading} type="primary" onClick={requestReport}>
//             Télécharger <CloudDownloadOutlined />
//           </Button>
//         </div>
//       </Modal>
//     </>
//   );
// };

// export default GeneralReport;


import React, { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { DatePicker, Button, Modal, Radio } from 'antd';
import API_URL from '../api/const';
import {
  CloudDownloadOutlined,
  CloseOutlined
} from '@ant-design/icons';
import { FileOutlined ,FileTextOutlined} from '@ant-design/icons'; // Import the FileOutlined icon from Ant Design
import { FilePdfOutlined, FileExcelOutlined } from '@ant-design/icons'; // Importing necessary icons
const { RangePicker } = DatePicker;

const GeneralReport = ({ endpoint }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState('pdf'); // Default to PDF report type

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setValue(null);
    setReportType('pdf'); // Reset report type to PDF when modal is closed
  };

  const requestReport = () => {
    setLoading(true);
    setErrorMessage('');

    if (!value || !value[0] || !value[1]) {
      setErrorMessage('Veuillez sélectionner une plage de dates.');
      setLoading(false);
      return;
    }

    const formatDateToYearMonthDay = date => moment(date).format('YYYY-MM-DD');

    axios.get(`${API_URL}${endpoint}/report`, {
      params: {
        startDate: formatDateToYearMonthDay(value[0].$d),
        endDate: formatDateToYearMonthDay(value[1].$d),
        reportType // Send selected report type to the backend
      },
      responseType: 'blob'
    })
      .then(response => {
        setLoading(false);
        const today = moment().format('YYYY-MM-DD_HH-mm-ss');
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',`${endpoint}_report_${today}.${reportType}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        setLoading(false);
        console.error('Error generating report: ', error);
        if (error.response && error.response.status === 502) {
          setErrorMessage('Aucune donnée trouvée dans la plage de dates.');
        } else {
          setErrorMessage('Error generating report. Please try again.');
        }
      });
  };

  return (
    <>
      <Button type="primary"  ghost icon={<FileTextOutlined />} onClick={showModal}>
        Rapports
      </Button>
    
      <Modal
        title="Export report"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 20 }}
        closeIcon={<CloseOutlined style={{ marginTop: '5px' }} />} // Align close icon vertically
      >
        {errorMessage && <span style={{ color: 'red', marginBottom: '12px' }}>{errorMessage}</span>}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <RangePicker value={value} onChange={setValue} style={{ marginBottom: '12px', marginRight: '16px', width: '100%' }}             placeholder={['Date de début', 'Date de fin']} />
          <Radio.Group value={reportType} onChange={e => setReportType(e.target.value)} style={{ marginBottom: '12px' }}>
          <Radio value="pdf">
        PDF <FilePdfOutlined /> {/* Include PDF icon */}
      </Radio>
       <Radio value="xlsx" disabled={true}>
        Excel <FileExcelOutlined /> {/* Include Excel icon */}
      </Radio> 
      
          </Radio.Group>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button loading={loading} type="primary" onClick={requestReport}>
              Télécharger <CloudDownloadOutlined />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default GeneralReport;
