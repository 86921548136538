// import React, {useRef, useState,useEffect } from 'react';

// import { DownOutlined } from '@ant-design/icons';
// import { Badge, Dropdown, Space, Table } from 'antd';
// import api from  '../api/prod';

// import {  Button } from 'antd';
// import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
// import UpdateDeleteButtons from '../UpdateDeleteButtons'
// const ProdDisplay = ({ refresh, onRefreshComplete }) => {
//   const [data, setData] = useState([]);
//   const fetchData = async () => {
//     try {
//       const result = await api.getAllProd();
// const restructuredContent = result.content.reduce((acc, curr) => {
//   const { codeEntite, dateProduction, dateDemarrage, dateArret, motifArret, ...rest } = curr;
//   const existingItem = acc.find(item => item.codeEntite === codeEntite && item.dateProduction === dateProduction);
//   if (existingItem) {
//       existingItem.list.push({codeEntite, dateDemarrage, dateArret, motifArret });
    
//   } else {
//     acc.push({
//       key:codeEntite + '_' + dateProduction,
//       codeEntite,
//       dateProduction,
//       ...rest,
//       list:  [{ codeEntite,dateDemarrage, dateArret, motifArret }]
//     });
//   }
//   return acc;
// }, [])


//   console.log(restructuredContent)
//      setData(restructuredContent);
//       onRefreshComplete();
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };


//   useEffect(() => {
//     if (refresh) {
//         fetchData();
//     }
// }, [refresh]);

//   // const expandedRowRender = (record) => {
//   //   // Filter records based on nomEntite and dateProduction
//   //   // const filteredRecords = data.filter(item => 
//   //   //   item.nomEntite === record.nomEntite && item.dateProduction === record.dateProduction
//   //   // );

//   //   const columns = [
//   //     {
//   //       title: 'Date Demarrage',
//   //       dataIndex: 'dateDemarrage',
//   //       key: 'dateDemarrage',
//   //     },
//   //     {
//   //       title: 'Date Arret',
//   //       dataIndex: 'dateArret',
//   //       key: 'dateArret',
//   //     },
//   //     {
//   //       title: 'Motif Arret',
//   //       dataIndex: 'motifArret',
//   //       key: 'motifArret',
//   //     },
//   //   ];

//   //   // const expandedData = record.list.map(item => ({
//   //   //   key: item.codeEntite, // Assuming codeEntite can uniquely identify each record
//   //   //   dateDemarrage: item.dateDemarrage || '-',
//   //   //   dateArret: item.dateArret || '-',
//   //   //   motifArret: item.motifArret || '-',
//   //   // }));

//   //   return <Table columns={columns} dataSource={record.list} pagination={false} />;
//   // };
//   const expandedRowRender = (record) => {
//     const columns = [
//       {
//         title: 'Date Demarrage',
//         dataIndex: 'dateDemarrage',
//         key: 'dateDemarrage',
//       },
//       {
//         title: 'Date Arret',
//         dataIndex: 'dateArret',
//         key: 'dateArret',
//       },
//       {
//         title: 'Motif Arret',
//         dataIndex: 'motifArret',
//         key: 'motifArret',
//       },
//       {
//         title: 'Action',
//         key: 'action',
//         render: (text, record) => (
//           record?.dateDemarrage  ? (
//             <Space size="middle">

//                 <UpdateDeleteButtons endpoint="stopProd" record={record} reloadData={fetchData} />
//               </Space>
           
           
//           ) : null
//         ),
//       },
//     ];
  
//     return <Table columns={columns} dataSource={record.list} pagination={false} />;
//   };

//   const columns = [
//     {
//       title: 'Name',
//       dataIndex: 'nomEntite',
//       key: 'nomEntite',
//     },
//     {
//       title: 'Date Production',
//       dataIndex: 'dateProduction',
//       key: 'dateProduction',
//     },
//     {
//       title: 'Produit',
//       dataIndex: 'produit',
//       key: 'produit',
//     },
//     {
//       title: 'Quantite',
//       dataIndex: 'quantite',
//       key: 'quantite',
//     },
//     {
//       title: 'Conditionnement',
//       dataIndex: 'conditionnement',
//       key: 'conditionnement',
//     },
//     {
//       title: 'Action',
//       key: 'action',
//       render: (text, record) => (
//         record  ? (
//           <Space size="middle">

//               <UpdateDeleteButtons endpoint="prod" record={record} reloadData={fetchData} />
//             </Space>
         
         
//         ) : null
//       ),
//     },
   
//   ];

//   return (
//     <>
//       <Table
//       scroll={{ x: 700 }}
//         columns={columns}
//         expandable={{
//           expandedRowRender, // Pass the expandedRowRender function with the correct parameter
//           defaultExpandedRowKeys: ['0'],
//         }}
//         dataSource={data}
//         size="small"
//       />
//     </>
//   );
// };

// export default ProdDisplay;

import React, { useRef, useState, useEffect } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Space, Table, Input, Form, InputNumber } from 'antd';
import api from '../api/prod';
import { Button } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import UpdateDeleteButtons from '../UpdateDeleteButtons';
import ProdEntryModel from "./prodEntryModel";
import moment from 'moment';
import StopProdModel from "./StopProdModel";
import { useCookies } from 'react-cookie'; // Import useCookies hook
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ProdDisplay = ({ refresh, onRefreshComplete,onSubmit }) => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['token']); // Use useCookies hook
  const [counter, setCounter] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // State to track current page

  const generateUniqueString = (prefix) => {
    setCounter(prevCounter => prevCounter + 1);
    return prefix + '__' + counter;
  };
  const fetchData = async () => {
    try {
      const result = await api.getAllProd();
      console.log("result")

      console.log(result)
      const restructuredContent = result.reduce((acc, curr) => {
        const {stopId,codeEntite, dateProduction, dateDemarrage, dateArret, motifArret, ...rest } = curr;
      
        // const existingItem = acc.find(item => {
        //   // Iterate over the properties of curr
        //   for (let prop in curr) {
        //     // Check if the property exists in existingItem and if their values are equal
        //     if (item[prop] !== curr[prop]) {
        //       return false; // If any property doesn't match, return false
        //     }
        //   }
        //   return true; // If all properties match, return true
        // });
        const existingItem = acc.find(item =>
          item.prodId ==
          curr.prodId
        );
        
        let pkey= codeEntite + '_' + dateProduction+'_'+curr.produit+'_'+curr.conditionnement+'_'+curr.prodId;
          console.log(pkey)
        if (existingItem) {
          existingItem.list.push({stopId:stopId,parentKey:pkey, key: pkey + '__'+Math.floor(Math.random() * 1000000), codeEntite, dateDemarrage, dateArret, motifArret,dateProduction,prodId:curr.prodId });
        } else {
        //  let pkey= generateUniqueString(codeEntite + '_' + dateProduction)
          acc.push({
            key: pkey,
            codeEntite,
            dateProduction,
            ...rest,
            list: [{stopId:stopId,parentKey:pkey,  key: pkey + '__'+Math.floor(Math.random() * 1000000), codeEntite, dateDemarrage, dateArret, motifArret ,dateProduction,prodId:curr.prodId }],
          });
        }
        return acc;
      }, []);
      restructuredContent.sort((a, b) => new Date(b.dateProduction) - new Date(a.dateProduction));
      console.log(restructuredContent);
      setData(restructuredContent);
      onRefreshComplete();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
    
  useEffect(() => {
    if (refresh) {
      fetchData();
    }
  }, [refresh]);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      nomEntite: '',
      dateProduction: '',
      produit: '',
      quantite: '',
      conditionnement: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const [searchText, setSearchText] = useState('');
const [searchedColumn, setSearchedColumn] = useState('');
const searchInput = useRef(null);
const [filteredInfo, setFilteredInfo] = useState({});
const [sortedInfo, setSortedInfo] = useState({});
const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};
const handleReset = (clearFilters) => {
  clearFilters();
  setSearchText('');
};
const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
        >
          Filter
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});


  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setCurrentPage(pagination.current); // Update current page when pagination changes

  };

  const save = async (key) => {
    try {
      console.log(key);
      const row = await form.validateFields();
      const newData = [...data];
      console.log(newData);
      let index = newData.findIndex((item) => key === item.key);

      
      if (index <= -1) {
        const parentIndex = newData.findIndex((item) => key.parentKey === item.key);
        if (parentIndex !== -1) {
          const listItemIndex = newData[parentIndex].list.findIndex((item) => key.key === item.key);
          if (listItemIndex !== -1) {
            const updatedList = [...newData[parentIndex].list]; // Create a new array to avoid mutating state directly
            updatedList.splice(listItemIndex, 1, {
              ...updatedList[listItemIndex],
              dateDemarrage: row.dateDemarrage,
              dateArret: row.dateArret,
              motifArret: row.motifArret
            });
            const updatedParentItem = {
              ...newData[parentIndex],
              list: updatedList
            };
            const updatedData = [...newData];
            // updatedData.splice(parentIndex, 1, updatedParentItem); // Update parent item in the newData array
          
            // setData(updatedData);
         
            updatedData.unshift(updatedParentItem); // Add updated parent item at the beginning of the array
            setData(updatedData);
            setEditingKey('');
          } else {
            console.error('List item not found with key:', key.key);
          }
        } else {
          console.error('Parent item not found with key:', key.parentKey);
        }
      }
      else { 
        const item = newData[index] 
        // newData.splice(index, 1, { ...item, ...row });
        // setData(newData);
          const updatedItem = { ...item, ...row }; // Merge item and row
          newData.unshift(updatedItem); // Add updated item as the first row
          setData(newData);

    
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const expandedRowRender = (record) => {
    let columns = [
      {
        title: "Heure début d'arrêt",
        dataIndex: 'dateDemarrage',
        key: 'dateDemarrage',
        editable: true,
      },
      {
        title: "Heure fin d'arrêt",
        dataIndex: 'dateArret',
        key: 'dateArret',
        editable: true,
      },
      {
        title: "Motif d'arrêt",
        dataIndex: 'motifArret',
        key: 'motifArret',
        editable: true,
      },
     
    ];

    if (cookies.username && !cookies.username.includes('viewer')) {
      columns.push(
      
        {
          title: 'Action',
          key: 'action',
          render: (text, record) =>
         //   record?.dateDemarrage   && moment(record.dateProduction).isSame(moment(), 'day')  ? (
              <Space size="middle">
                <StopProdModel  onSubmit={onSubmit} isCreated={false} initialFormData = {{
                                              codeEntite: record.codeEntite,
                                              dateProduction: record.dateProduction,
                                              dateDemarrage: record.dateDemarrage , 
                                              dateArret:  record.dateArret,
                                              motifArret:  record. motifArret,
                                              stopId:record.stopId,
                                              prodId:record.prodId
                                            }} />
            
  
                <UpdateDeleteButtons endpoint="stopProd" record={record} reloadData={fetchData}  isMainDelete={false}/>
              </Space>
         //   ) : null,
        },
      );
    }

    const mergedColumns = columns.map((col) => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType:  'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

    return (
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          columns={mergedColumns}
          dataSource={record.list}
          pagination={false}
        />
      </Form>
    );
  };

  let columns = [
    {
      title: 'Entité',
      dataIndex: 'nomEntite',
      key: 'nomEntite',
      filteredValue: filteredInfo.nomEntite || null,
      sorter: (a, b) => a.nomEntite.localeCompare(b.nomEntite),
      sortOrder: sortedInfo.columnKey === 'nomEntite' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('nomEntite'),
    },
    {
      title: 'Date Production',
      dataIndex: 'dateProduction',
      key: 'dateProduction',
      filteredValue: filteredInfo.dateProduction || null,
      sorter: (a, b) => new Date(a.dateProduction) - new Date(b.dateProduction),
      sortOrder: sortedInfo.columnKey === 'dateProduction' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('dateProduction'),
    },
    {
      title: 'Produit',
      dataIndex: 'produit',
      key: 'produit',
      filteredValue: filteredInfo.produit || null,
      sorter: (a, b) => a.produit.localeCompare(b.produit),
      sortOrder: sortedInfo.columnKey === 'produit' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('produit'),
    },
    {
      title: 'Quantite',
      dataIndex: 'quantite',
      key: 'quantite',
      filteredValue: filteredInfo.quantite || null,
      sorter: (a, b) => a.quantite - b.quantite,
      sortOrder: sortedInfo.columnKey === 'quantite' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('quantite'),
    },
    {
      title: 'Conditionnement',
      dataIndex: 'conditionnement',
      key: 'conditionnement',
      filteredValue: filteredInfo.conditionnement || null,
      sorter: (a, b) => a.conditionnement.localeCompare(b.conditionnement),
      sortOrder: sortedInfo.columnKey === 'conditionnement' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('conditionnement'),
    },
    
  ];
  if (cookies.username && !cookies.username.includes('viewer')) {
    columns.push(
    
      {
        title: 'Action',
        key: 'action',
        render: (text, record) =>
  
       //   record && moment(record.dateProduction).isSame(moment(), 'day')  ? (
            <Space size="middle">
          <StopProdModel  onSubmit={onSubmit} isCreated={true} initialFormData = {{
                                              codeEntite: record.codeEntite,
                                              dateProduction: record.dateProduction,
                                              dateDemarrage: record.dateDemarrage , 
                                              dateArret:  record.dateArret,
                                              motifArret:  record. motifArret,
                                              stopId:record.stopId,
                                              prodId:record.prodId
                                            }} />
                      
                <ProdEntryModel  onSubmit={onSubmit} isCreated={false} initialFormData = {{
                                              codeEntite: record.codeEntite,                  // String
                                            //  dateProduction: moment(record.dateProduction, 'YYYY-MM-DD') ,  
                                              dateProduction: record.dateProduction ,    
                                                  // Assuming you're using Moment.js
                                              qteProduction: parseFloat(record.quantite),              // Float
                                              codeProduit: record.produit,                 // String
                                              codeCond: record.conditionnement}} />
  
              <UpdateDeleteButtons endpoint="prod" record={record} reloadData={fetchData} />
            </Space>
       //   ) : null,
      },
    );
  }
  // const mergedColumns = columns.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }

  //   return {
  //     ...col,
  //     onCell: (record) => ({
  //       record,
  //       inputType: col.dataIndex === 'quantite' ? 'number' : 'text',
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       editing: isEditing(record),
  //     }),
  //   };
  // });

  return (
    <>
        <Table
          scroll={{ x: 700 }}
     
          columns={columns}
        
          dataSource={data}
          onChange={handleChange}  
          pagination={{ pageSize: 20 }}
          expandable={{expandedRowRender}}
          
        />
    </>
  );
};

export default ProdDisplay;