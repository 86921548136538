import React, { useEffect,Component } from 'react';
import { Button, Form, Input,Select,DatePicker } from 'antd';
import {
  PlusSquareOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import moment from 'moment';
import  { useState } from 'react';
import {  Modal } from 'antd';
import { Row, Col } from 'antd';
import axios from 'axios';

import api from  '../api/prod';
import API_URl from  '../api/const';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Notifiyer from '../notification/index';
import dayjs from 'dayjs';

// const  ProdEntryModel = ({ onSubmit, isCreated = true,initialFormData 
// //   = {
// //   codeEntite: "Usine_1",                  // String
// //   dateProduction: moment(),        // Assuming you're using Moment.js
// //   qteProduction: 0,              // Float
// //   codeProduit: "000001",                 // String
// //   codeCond: "000001",                    // String
// // }
//  }) => {

//    console.log(" obj ", initialFormData)
// const [formData, setFormData] = useState(initialFormData);
// const [errorMessage, setErrorMessage] = useState('');
// const [submittedMessage, setsubmittedMessage] = useState('');
// const [selectOptions, setSelectOptions] = useState([]);

// const [productsOptions, setproductsOptions] = useState([]);
// const [form] = Form.useForm();

// useEffect(() => {
//   // Fetch select options from API when component mounts
//   axios.get(`${API_URl}/productions/units`)
//     .then(response => {
//       setSelectOptions(response.data);
//     })
//     .catch(error => {
//       console.error('Error fetching select options:', error);
//     });

//     axios.get(`${API_URl}/productions/products`)
//     .then(response => {
//       setproductsOptions(response.data);
//     })
//     .catch(error => {
//       console.error('Error fetching select options:', error);
//     });
// }, []); // Empty dependency array ensures that this effect runs only once on mount


// const handleChange = (name, value) => {
//   setFormData({ ...formData, [name]: value });
// };
  
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const showModal = () => {
//     setFormData(initialFormData);

//     setIsModalOpen(true);

//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//     form.resetFields();

//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//     setFormData(initialFormData);
//     form.resetFields();


//   };
//   const handleSubmit = () => {
  
//     try{

//       console.log(formData)
//       if(isCreated){
//         api.addProd(formData);
//         console.log("addProd")


//       }else {
//         api.updateProd(formData);
//    console.log("updateProd")
//       }
//       Notifiyer();
//       handleCancel();
  

//       // onSubmit();
//       // setsubmittedMessage("Données enregistrées.");
//       setsubmittedMessage('Données enregistrées.');
//       setTimeout(() => {
//         setFormData(initialFormData);
//         form.resetFields();
//       onSubmit();
//       }, 1000);
//       setTimeout(() => {
//           setsubmittedMessage('');
//         }, 3000);
//     } catch (error) {
//       console.error('Failed to submit form data:', error);
//       setErrorMessage('Failed to submit form data. Please try again.',error);

//       setTimeout(() => {
//         setErrorMessage('');
//       }, 3000);
//     }

  
//   };
  
//   const createFunction = (libelle) => {
//     // Iterate through selectOptions to find the matching libelle
//     for (const option of selectOptions) {
//       if (option.libelle === libelle) {
//         return option.codeCond; // Return the corresponding codeCond
//       }
//     }
//     return undefined; // Return undefined if libelle is not found
//   };
//   const createFunction1 = (libelle) => {
//     // Iterate through selectOptions to find the matching libelle
//     for (const option of productsOptions) {
//       if (option.libelle === libelle) {
//         return option.codeProduit; // Return the corresponding codeCond
//       }
//     }
//     return undefined; // Return undefined if libelle is not found
//   };
//     return (
// <>


// {isCreated ? (
//         <Button type="primary" onClick={showModal}>

//         < PlusSquareOutlined style={{ fontSize: '17px' }} /> Ajouter  
        
//         </Button>
//       ) : (
//         <Button type="primary" icon={<EditOutlined />} size="small" onClick={showModal}></Button>
//       )}


      
//        <Modal title="Production" 
//         open={isModalOpen}
//         onOk={handleOk} 
//         onCancel={handleCancel}
//         footer={null}
       
//        style={{ top: 20 }}
//        >
//           {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
//       {submittedMessage && <p style={{ color: 'green' }}>{submittedMessage}</p>}

//         <Form 
//         layout='horizontal'
//         name="basic"
//         autoComplete="off"
//         onFinish={handleSubmit}
        
//       >

//        <Form.Item label="Entité de production:"
       
//        >
//             <Select   
//              defaultValue = {formData.codeEntite}
//              disabled={!isCreated}
//              onChange = {(value) => handleChange('codeEntite', value)}
//             >
//             <Select.Option value="Usine_1">Usine_1</Select.Option>
//             <Select.Option value="Usine_2">Usine_2</Select.Option>
//             <Select.Option value="Broilleur">Broilleur</Select.Option>

//             </Select>
//        </Form.Item>



//        <Form.Item label="Date de production:">
//         <DatePicker style={{ width: '100%' }}  
//           disabled={!isCreated}
//          defaultValue={formData.dateProduction}
//          onChange={(date) => handleChange('dateProduction', date)}
//         />
//       </Form.Item>
      

//       <Row gutter={[16, 16]} justify="start">
//       <Col xs={24} sm={8} md={15} >
//       <Form.Item
//           label="Quantité produite :"
//           name="Quantité produite"
//           rules={[
//             {
//               required: true,
//               message: 'Please add quantity',
//             },
//           ]}
//         >
//           <Input    defaultValue={formData.qteProduction}
//             onChange={(e) => handleChange('qteProduction', e.target.value)}
//              />
//         </Form.Item>
//       </Col>
//       <Col xs={24} sm={8} md={9}  >
//       <Form.Item label="">
//             <Select 
//              defaultValue = { isCreated ? formData.codeCond :createFunction(formData.codeCond) }
//              onChange = {(value) => handleChange('codeCond', value)}
//             >
//             {/* <Select.Option value="000001">Tonne</Select.Option>
//             <Select.Option value="000002">Sac 25 Kgrs</Select.Option>
//             <Select.Option value="000003">Sac 50 Kgrs</Select.Option>
//             <Select.Option value="000004">Sac 100 Kgrs</Select.Option> */}
       
//        {selectOptions.map((option, index) => (
//   <Select.Option key={index} value={option.codeCond}>
//     {option.libelle}
//   </Select.Option>
// ))}

//             </Select>
//        </Form.Item>
//       </Col>
    
//     </Row>
  

    


//       <Form.Item label="Produit:">
//             <Select 
//              defaultValue = { isCreated ? formData.codeProduit :createFunction1(formData.codeProduit) }

//              onChange = {(value) => handleChange('codeProduit', value)}
//             >
//             {/* <Select.Option value="000001">Riz blanc</Select.Option>
//             <Select.Option value="000002">Riz brisure</Select.Option>
//             <Select.Option value="000003">Bale de riz</Select.Option>
//             <Select.Option value="000004">Son de riz</Select.Option> */}
//                    {productsOptions.map((option, index) => (
//   <Select.Option key={index} value={option.codeProduit}>
//     {option.libelle}
//   </Select.Option>
// ))}
//             </Select>
//        </Form.Item>

      

    

//         <Form.Item>
//           <Button type="primary" htmlType="submit">
//           Sauvegarder
//           </Button>
//         </Form.Item>
//       </Form>
//        </Modal>
// </>

     
       
   
//     );
  
// }

// export default ProdEntryModel;


const ProdEntryModel = ({ onSubmit, isCreated = true, initialFormData }) => {
  console.log(" obj ", initialFormData);
  const [formData, setFormData] = useState(initialFormData);
  const [errorMessage, setErrorMessage] = useState('');
  const [submittedMessage, setsubmittedMessage] = useState('');
  const [selectOptions, setSelectOptions] = useState([]);
  const [productsOptions, setProductsOptions] = useState([]);
  const [form] = Form.useForm();
 const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    axios.get(`${API_URl}/productions/units`)
      .then(response => {
        setSelectOptions(response.data);
      })
      .catch(error => {
        console.error('Error fetching select options:', error);
      });

    axios.get(`${API_URl}/productions/products`)
      .then(response => {
        setProductsOptions(response.data);
      })
      .catch(error => {
        console.error('Error fetching select options:', error);
      });
  }, []);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleModalOpen = () => {
    setFormData(initialFormData);
    form.resetFields();
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFormData(initialFormData);
    form.resetFields();
  };

  const handleSubmit = () => {
    try {
      console.log("***************** formData before sumit *****************");

      if (isCreated) {
        console.log(formData);

        api.addProd(formData);
      } else {
        api.updateProd(formData);
      }
      Notifiyer();
      handleCancel();
      setsubmittedMessage('Données enregistrées.');
      setTimeout(() => {
        setFormData(initialFormData);
        form.resetFields();
        onSubmit();
      }, 1000);
      setTimeout(() => {
        setsubmittedMessage('');
      }, 3000);
    } catch (error) {
      console.error('Failed to submit form data:', error);
      setErrorMessage('Failed to submit form data. Please try again.');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  const createFunction = (libelle) => {
    for (const option of selectOptions) {
      if (option.libelle === libelle) {
        return option.codeCond;
      }
    }
    return undefined;
  };

  const createFunction1 = (libelle) => {
    for (const option of productsOptions) {
      if (option.libelle === libelle) {
        return option.codeProduit;
      }
    }
    return undefined;
  };

  return (
    <>
      {isCreated ? (
        <Button type="primary" onClick={handleModalOpen}>
          <PlusSquareOutlined style={{ fontSize: '17px' }} /> Ajouter
        </Button>
      ) : (
        <Button 
         ghost
        style={{ background: '#00AFF0', color: 'white' }}
       // type="primary" 
        icon={<EditOutlined />} 
        size="small"
         onClick={handleModalOpen}></Button>
      )}

      <Modal
        title="Production"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 20 }}
      >
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {submittedMessage && <p style={{ color: 'green' }}>{submittedMessage}</p>}

        <Form
          form={form}
          layout='horizontal'
          name="basic"
          autoComplete="off"
          onFinish={handleSubmit}
          initialValues={{
            codeEntite: initialFormData.codeEntite,
            dateProduction: initialFormData.dateProduction ? moment(initialFormData.dateProduction) : null,
            qteProduction: initialFormData.qteProduction,
            codeCond: initialFormData.codeCond,
            codeProduit: initialFormData.codeProduit,
          }}
        >

          <Form.Item label="Entité de production:" name="codeEntite">
            <Select
              defaultValue={formData.codeEntite}
              disabled={!isCreated}
              onChange={(value) => handleChange('codeEntite', value)}
            >
              <Select.Option value="Usine_1">Usine_1</Select.Option>
              <Select.Option value="Usine_2">Usine_2</Select.Option>
              <Select.Option value="Broilleur">Broilleur</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item label="Date de production:" name="dateProduction">
            <DatePicker
              style={{ width: '100%' }}
              disabled={!isCreated}
           //   defaultValue={formData.dateProduction && !isCreated ? moment(formData.dateProduction) : null}
            //  defaultValue={ moment(formData.dateProduction) }

             placeholder="Sélectionnez une date"
             onChange={(date) => handleChange('dateProduction', dayjs(date).format('YYYY-MM-DD'))}

           //  onChange={(date) => handleChange('dateProduction', date.toISOString().split('T')[0])}
            />
          </Form.Item>

          <Row gutter={[16, 16]} justify="start">
            <Col xs={24} sm={8} md={15}>
              <Form.Item
                label="Quantité produite :"
                name="qteProduction"
                rules={[
                  {
                    required: true,
                    message: 'Please add quantity',
                  },
                  
    {
      pattern: /^[0-9]+(?:\.[0-9]+)?$/, // Regular expression to accept integers or floats
      message: 'Please enter a valid number',
    },
                ]}
              >
                <Input
                  defaultValue={formData.qteProduction}
                  onChange={(e) => handleChange('qteProduction', e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={8} md={9}>
              <Form.Item label="" name="codeCond">
                <Select
                  defaultValue={isCreated ? formData.codeCond : createFunction(formData.codeCond)}
                  onChange={(value) => handleChange('codeCond', value)}
                >
                  {selectOptions.map((option, index) => (
                    <Select.Option key={index} value={option.codeCond}>
                      {option.libelle}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="Produit:" name="codeProduit">
            <Select
              defaultValue={isCreated ? formData.codeProduit : createFunction1(formData.codeProduit)}
              onChange={(value) => handleChange('codeProduit', value)}
            >
              {productsOptions.map((option, index) => (
                <Select.Option key={index} value={option.codeProduit}>
                  {option.libelle}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Sauvegarder
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ProdEntryModel;
