
import axios from 'axios';
import API_URl from  './const';
const endpoint = '/arret-prod';

const addProdArret = (formData) => {
    axios.post(API_URl + endpoint, formData)
      .then((response) => {
        console.log('Form data submitted successfully:', response.data);
        //update store here
      })
      .catch((error) => {
        console.error('Error submitting form data:', error);
      });
  };

  const updateProdArret = (formData) => {
    axios.put(API_URl + endpoint, formData)
      .then((response) => {
        console.log('Form data submitted successfully:', response.data);
        //update store here
      })
      .catch((error) => {
        console.error('Error submitting form data:', error);
      });
  };
  

  export default {
    addProdArret,
    updateProdArret
  }