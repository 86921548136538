

import React, { useState,useEffect  } from 'react';
import {Table, Button, Form, Input, Select, DatePicker, Checkbox, Modal,Tag  } from 'antd';
import moment from 'moment';
import { Row, Col } from 'antd';
import api_emp from  '../api/employee';
import api_pointage from  '../api/pointage';
import Notifiyer from '../notification/index';
import dayjs from 'dayjs';

import {
  PlusSquareOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

const PointEntryModel = ({ onSubmit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pointageList, setPointageList] = useState([]);
  const [selectedPointageItems, setSelectedPointageItems] = useState([]);
  const [initialSelectedPointageItems, setInitialSelectedPointageItems] = useState([]);
  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api_emp.getAllEmployee();
        const currentDate = moment().format("YYYY-MM-DD");
        const updatedData = result.content.map((item, index) => ({
          ...item,
          key: item.identite,
        //  identite :item.identite,
          date: currentDate // Adding today's date to each row
        }));
        setPointageList(updatedData);

        const initialItems = updatedData.map((item) => ({
          datePointage: item.date, // Assuming you'll assign a LocalDate object here
          identite: item.identite,
          codeEntite: "Usine_2",
          present: true,
          nomOuvriers: item.nomPrenom
        }));
        setInitialSelectedPointageItems(initialItems);
        setSelectedPointageItems(initialItems);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setSelectedRowKeys(pointageList.map(item => item.identite));
  }, [isModalOpen]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedRowKeys([]);
    setLoading(false);
  };

  // const onChangeDate = (e) => {
  //   const newDate = e.target.value;
  //   setSelectedDate(newDate);
  //   // Update the span value
  //   const updatedPointageList = pointageList.map(item => ({ ...item, date: newDate }));
  //   setPointageList(updatedPointageList);
  // };

  const onChangeDate = (date, dateString) => {
    setSelectedDate(dateString); // Update the selected date with the date string
  
    // Update the span value
    const updatedPointageList = pointageList.map(item => ({ ...item, date: dateString }));
    setPointageList(updatedPointageList);
  };
  const handleSubmit = () => {
    setLoading(true);
    const updatedSelectedPointageItems = selectedPointageItems.map(item => ({
      ...item,
      datePointage: selectedDate
    }));
    console.log("updatedSelectedPointageItems");
    console.log(updatedSelectedPointageItems);

    console.log("selectedRowKeys");
    console.log(selectedRowKeys);



      const handleSubmit = async () => {
        try {
          setLoading(true);
           api_pointage.addPointage(updatedSelectedPointageItems);
          Notifiyer();
          onSubmit();
        } catch (error) {
          console.error('Error submitting data:', error);
        } finally {
          setLoading(false);
          setSelectedPointageItems(initialSelectedPointageItems);
          setSelectedRowKeys([]);
          handleCancel();
        }
      };

      handleSubmit();
      
  };

  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
    console.log("selectedPointageItems");

    console.log(selectedPointageItems);
    const updatedSelectedPointageItems = selectedPointageItems.map((item, index) => ({
      ...item,
      present: selectedRowKeys.includes(item.identite)
    }));
    setSelectedPointageItems(updatedSelectedPointageItems);
  };

  const columns = [
    {
      title: 'Nom Prenom',
      dataIndex: 'nomPrenom',
    },
    {
      title: 'identite',
      dataIndex: 'identite',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: () => (
        <span>{selectedDate}</span>
      )
    }
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  return (
    <>
      <Button type="primary" onClick={showModal}>
        <PlusSquareOutlined style={{ fontSize: '17px' }} /> Ajouter
      </Button>
      <Modal
        destroyOnClose={true}
        title="Pointage"
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 20 }}
      >
        <div>
    


          {/* <div style={{ display: 'flex', alignItems: 'center' }}>
  <Button
    type="primary"
    onClick={handleSubmit}
    loading={loading}
  >
    Sauvegarder
  </Button>
  <span style={{ marginLeft: 30 }}>
   
      <Tag color="geekblue"  style={{ fontSize: '16px', fontWeight: 'bold' }}>
     
        {hasSelected ? `${selectedRowKeys.length} Présent` : ''}
      
      </Tag>
   
  </span>
  <span>
    <DatePicker
      style={{ width: '100%', marginLeft: 50 }}
      defaultValue={dayjs(selectedDate)}
      placeholder="Sélectionnez une date"
      onChange={onChangeDate}
    />
  </span>
</div> */}


<Row gutter={16} align="middle">
  <Col flex="auto">
    <Button
      type="primary"
      onClick={handleSubmit}
      loading={loading}
    >
      Sauvegarder
    </Button>
  </Col>
  <Col  flex="auto">
    <Tag color="blue" style={{ fontSize: '16px', fontWeight: 'bold' }}>
      {hasSelected ? `${selectedRowKeys.length} Présent` : '0 Présent'}
    </Tag>
  </Col>
  <Col  flex="auto">
    <DatePicker
      style={{ width: '100%' }}
      defaultValue={dayjs(selectedDate)}
      placeholder="Sélectionnez une date"
      onChange={onChangeDate}
    />
  </Col>
</Row>


<div style={{ overflowX: 'auto' }}>
  <Table rowSelection={rowSelection} columns={columns} dataSource={pointageList} />
</div>
        </div>
      </Modal>
    </>
  );
};

export default PointEntryModel;
