import IndexsModel from "./indexsCreateModel";
import IndexsUnitPriceModel from "./indexUnitPrice";
import IndexsReportModel from "./indexsReportModel";
import IndexsDisplay from "./indexsDisplay";
import { Row, Col } from 'antd';
import React, {useRef, useState,useEffect  } from 'react';
import RefreshButton from '../refresh'
import { useCookies } from 'react-cookie';
const IndexsMain = () => {
  const [cookies] = useCookies(['username']);
  const [refreshDisplay, setRefreshDisplay] = useState(true);
  const isAdmin = cookies.username && cookies.username.includes('admin');
  const handleModelSubmit = () => {
    console.log("handleModelSubmit() -*******************")
      setRefreshDisplay(true);
  };
return (
    <>
      <Row gutter={[16, 16]} justify="start">
      {cookies.username && !cookies.username.includes('viewer') && (
      <Col xs={24} sm={24} md={3}>
        <IndexsModel  onSubmit={handleModelSubmit}/>
      </Col>
         )}

      {isAdmin && (
          <Col xs={24} sm={24} md={4}>
            <IndexsUnitPriceModel />
          </Col>
        )}
      <Col xs={24} sm={24} md={3} >
        <IndexsReportModel />
      </Col>
      {cookies.username && !cookies.username.includes('viewer') && (
      <Col xs={24} sm={24} md={3}>
      <RefreshButton handleModelSubmit ={handleModelSubmit} />
      </Col>
       )}
    </Row>
    <Row gutter={[16, 16]} justify="start">
      <Col xs={24} sm={24} md={24}>
        <IndexsDisplay  refresh={refreshDisplay} onRefreshComplete={() => setRefreshDisplay(false)}/>
      </Col>
    </Row>
      
        </>
    );
}

export default IndexsMain;
