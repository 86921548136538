import React, { useState } from 'react';
import GeneralReport from '../reporting';


const PointReportModel = () => {
 
  return (
    <>
     <GeneralReport endpoint="/pointages"/>
    </>
  );
};
export default PointReportModel;