
import axios from 'axios';
import API_URl from  './const';
const endpoint = '/tcouts';

const upadateCoutUnitaire = (currentCoutUnitaire) => {
    axios.post(API_URl + endpoint, {
        cunitaire:currentCoutUnitaire,
        codeEntite:"Usine_1"
    })
      .then((response) => {
        console.log('upadateCoutUnitaire submitted successfully:', response.data);
      })
      .catch((error) => {
        console.error('Error submitting upadateCoutUnitaire  data:', error);
      });
  };

  const getCoutUnitaire = () => {
    return axios.get(`${API_URl + endpoint}`)
      .then((response) => {
        console.log('Data fetched successfully:', response.data);
        return response.data; 
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        throw error; 
      });
  };
  export default {
    upadateCoutUnitaire,
    getCoutUnitaire
    
  }