
import axios from 'axios';
import API_URl from  './const';
const endpoint = '/productions';

const addProd = (formData) => {
    axios.post(API_URl + endpoint, formData)
      .then((response) => {
        console.log('Form data submitted successfully:', response.data);
        //update store here
      })
      .catch((error) => {
        console.error('Error submitting form data:', error);
      });
  };

  const getAllProd = (pageNumber=0, pageSize=1000000) => {
    return axios.get(`${API_URl + endpoint}`)
      .then((response) => {
        console.log('Data fetched successfully:', response.data);
        return response.data; // Return data here
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        throw error; // Re-throw the error to maintain consistency
      });
  };
  
  
  const findProd = (formData) => {
    //return axios.post(`${API_URl + endpoint+"/find"}`,formData.prodid)
    console.log("rest ")
    console.log(formData)
    return axios.post(`${API_URl + endpoint + "/find"}?id=${formData.prodId}`)
      // .then((response) => {
      //   console.log('Data fetched successfully:', response.data);
      //   return response; // Return data here
      // })
      // .catch((error) => {
      //   console.error('Error fetching data:', error);
      //   throw error; // Re-throw the error to maintain consistency
      // });
  };
  

  const updateProd = (formData) => {
    axios.put(API_URl + endpoint, formData)
      .then((response) => {
        console.log('Form data submitted successfully:', response.data);
        //update store here
      })
      .catch((error) => {
        console.error('Error submitting form data:', error);
      });
  };

  export default {
    addProd,
    getAllProd,
    findProd,
    updateProd
  }