import React, {useRef, useState,useEffect  } from 'react';

import { Row, Col ,Button} from 'antd';
import EmpEntryModel from './employeeEntryModel';
import EmpDisplay from './employeeDisplay';
import EmployeeReportModel from './employeeReport';
import moment from 'moment';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import { useCookies } from 'react-cookie'; // Import useCookies hook

import RefreshButton from '../refresh'
const EmpMain = ()=>{
  const [refreshDisplay, setRefreshDisplay] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(['token']); // Use useCookies hook

  const handleModelSubmit = () => {
    setRefreshDisplay(true); // Toggle refreshDisplay state to trigger display refresh
    };
    return (
        <>
    {cookies.username && !cookies.username.includes('viewer') && (

      <Row gutter={[16, 16]} justify="start">
      <Col xs={24} sm={8} md={3}>
        <EmpEntryModel 
         isCreated={true}
        onSubmit={handleModelSubmit}  initialFormData ={{
        nomPrenom: '',
        identite: '',
        dateTravail: null,
        codeEntite:'Usine_1',
        adresse: '',
        }} />
      </Col>  
      
      <Col xs={24} sm={8} md={3} >
        <RefreshButton handleModelSubmit ={handleModelSubmit} />
      </Col>
    
    </Row>
  )}
    <Row gutter={[16, 16]} justify="start">
      <Col xs={24} sm={24} md={24}>
      <EmpDisplay  refresh={refreshDisplay} onRefreshComplete={() => setRefreshDisplay(false)} />
        </Col>
        </Row>
      
        </>
    );
}

export default EmpMain;
