import React from 'react';
import { Button } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

const RefreshButton = ({ handleModelSubmit }) => {
  return (
    <Button 
    
     type="primary" danger ghost
     icon={<ReloadOutlined />}
     onClick={handleModelSubmit}>
      Rafraîchir
    </Button>
  );
};

export default RefreshButton;
