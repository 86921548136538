import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import IndexsForm from './indexsForm';
import {
  PlusSquareOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';

const IndexsModel = ({ onSubmit }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button type="primary" onClick={showModal} >
       < PlusSquareOutlined style={{ fontSize: '17px' }} /> Ajouter  
      </Button>
      
      <Modal title="Indexes" 
       destroyOnClose={true}
      //  open={isModalOpen}
       visible={isModalOpen} // Use visible prop instead of open
       onOk={handleOk} 
       onCancel={handleCancel}
       footer={null}
       style={{ top: 20 }}
      >
          <IndexsForm  onSubmit={onSubmit} closeModal={handleCancel}/>
      </Modal>
    </>
  );
};
export default IndexsModel;