import React, { useState ,useEffect } from 'react';
import { Button, Form, Input, Select, DatePicker } from 'antd';
import moment from 'moment';
import api from  '../api/indexdata';
import Notifiyer from '../notification/index';
import dayjs from 'dayjs';

const IndexsForm = ({ onSubmit, closeModal }) => {
  const initialFormData = {
    previousIndex: '0',
    codeEntite: 'Usine_1', 
    dateDuJour: null, 
    compteur: '000001', 
    indexDuJour: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errorMessage, setErrorMessage] = useState('');
  const [submittedMessage, setsubmittedMessage] = useState('');

  

  // const handleChange = (name, value) => {
  //   setFormData({ ...formData, [name]: value });
  // };
  const handleChange = (name, value) => {
    let updatedFormData = { ...formData, [name]: value };
  
    // Update 'compteur' based on the selected 'codeEntite'
    if (name == 'codeEntite' && value == "Usine_1") {
      console.log(name + "   if " +value);
      updatedFormData = { ...updatedFormData, compteur: '000001'};
    } else if (name == 'codeEntite' && value == "Broilleur") {
      console.log(name + "  else   " +value);

      updatedFormData = { ...updatedFormData, compteur: '000002'};

    }
    console.log(updatedFormData);

    setFormData(updatedFormData);
  };

  const handleSubmit = () => {
  
    try {
      api.addIndex(formData);
      setFormData(initialFormData);
     
      setsubmittedMessage('Données enregistrées.');
      setTimeout(() => {
      fetchData();
      onSubmit();
      }, 1000);
      setTimeout(() => {
          setsubmittedMessage('');
        }, 3000);
        closeModal(); // Close the modal

        Notifiyer();
    } catch (error) {
      console.error('Failed to submit form data:', error);
      setErrorMessage('Failed to submit form data. Please try again.',error);

      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }

  };




function fetchData() {
  api.getLatestIndexes()
      .then(result => {
          console.log("Result from API:", result.data);
          console.log("formData.codeEntite:", formData.codeEntite);
          
          const entry = result.data.find(item => item.codeEntite == formData.codeEntite);
      
          if (entry) {
              handleChange('previousIndex', entry.ancienIndex);
          } else {
            handleChange('previousIndex', 0);

              console.error("Entry not found for codeEntite:", formData.codeEntite);
          }
      })
      .catch(error => {
          console.error("Error fetching data:", error);
      });
}
useEffect(() => {

  fetchData();
}, [formData.codeEntite]);

  

  return (
    <div>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

      <Form layout="horizontal" onFinish={handleSubmit}>
        <Form.Item label="Usine">
          <Select
            defaultValue={formData.codeEntite}
            onChange={(value) => handleChange('codeEntite', value)}
          >
            <Select.Option value="Usine_1">Usine_1</Select.Option>
            <Select.Option value="Broilleur">Broilleur</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Division">
          {/* <Select 
            defaultValue={formData.compteur}
          //  onChange={(value) => handleChange('compteur', value)}
          >
            <Select.Option value="000001">000001 - Principal</Select.Option>
            <Select.Option value="000002">000002 - Divisionnaire</Select.Option>
          </Select> */}

         <Input 
            value={formData.compteur == "000001" ?  "000001 - Principal": "000002 - Divisionnaire"}
            disabled
          />
        </Form.Item>
        <Form.Item label="Date Du Jour">
          <DatePicker
            style={{ width: '100%' }}
           // defaultValue={formData.dateDuJour}
            placeholder="Sélectionnez une date"
            onChange={(date) => handleChange('dateDuJour', dayjs(date).format('YYYY-MM-DD'))}
          />
        </Form.Item>
        <Form.Item
          label="Index Du Jour"
          name="indexDuJour"
          rules={[{ required: true, message: '' }, {
            pattern: /^[0-9]+(?:\.[0-9]+)?$/, // Regular expression to accept integers or floats
            message: 'Please enter a valid number',
          }]}
        >
          <Input
            value={formData.indexDuJour}
            onChange={(e) => handleChange('indexDuJour', e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Index précédent">
          <Input value={formData.previousIndex} disabled />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
          Sauvegarder

          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default IndexsForm;
