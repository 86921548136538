
import axios from 'axios';
import API_URl from  './const';
const endpoint = '/ouvriers';

const addEmployee = (formData) => {
    axios.post(API_URl + endpoint, formData)
      .then((response) => {
        console.log('Form data submitted successfully:', response.data);
        //update store here
      })
      .catch((error) => {
        console.error('Error submitting form data:', error);
      });
  };

  const getAllEmployee = (pageNumber=0, pageSize=1000000) => {
    return axios.get(`${API_URl + endpoint}?page=${pageNumber}&size=${pageSize}`)
      .then((response) => {
        console.log('Data fetched successfully:', response.data);
        return response.data; // Return data here
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        throw error; // Re-throw the error to maintain consistency
      });
  };
  
  

  

  export default {
    addEmployee,
    getAllEmployee
  }