import { createStore,combineReducers  } from 'redux'; 
import api_cout from '../api/coutUnitaire';
import api_pointage from '../api/pointage';
import api_emp from '../api/employee';
import api_prod from '../api/prod';
import Cookies from 'js-cookie';


const initialState = {
    coutUnitaire: 0, 
  };
  
  
  const coutUnitaireReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'UPDATE_COUT_UNITAIRE':
        return {
          ...state,
          coutUnitaire: action.payload,
        };
      default:
        return state;
    }
  };
  

  // Initial state for pointage reducer
const initialPointageState = {
  content:[]
};

// Reducer function for pointage
const pointageReducer = (state = initialPointageState, action) => {
  switch (action.type) {
    case 'ALL_POINTAGE':
      console.log('ALL_POINTAGE action dispatched. Payload:', action.payload);
    return {
      ...state,
      content: action.payload,
    };
    default:
      return state;
  }
};

const initialEmpState= {
  content:[]

};
//employeeReducer
const employeeReducer = (state = initialEmpState, action) => {
  switch (action.type) {
    case 'ALL_EMP':
      console.log('ALL_EMP action dispatched. Payload:', action.payload);
    return {
      ...state,
      content: action.payload,
    };
    default:
      return state;
  }
};

const initialProdState= {
  content:[]
};

//prodReducer
const prodReducer = (state = initialProdState, action) => {
  switch (action.type) {
    case 'ALL_PROD':
      console.log('ALL_PROD action dispatched. Payload:', action.payload);
    return {
      ...state,
      content: action.payload,
    };
    default:
      return state;
  }
};

// Combine reducers
const rootReducer = combineReducers({
  coutUnitaire: coutUnitaireReducer,
  pointage: pointageReducer,
  emp:employeeReducer,
  prod:prodReducer
});

  const store = createStore(rootReducer);


  const initializeProd = async () => {
    try {
      const response = await api_prod.getAllProd();
      console.log("ALL_PROD");
      console.log(response);
      store.dispatch({ type: "ALL_PROD", payload: response.content });
    } catch (error) {
      console.error('Error fetching initial state:', error);
    }
  };


  const initializeEmp = async () => {
    try {
      const response = await api_emp.getAllEmployee();
      console.log("ALL_EMP");
      console.log(response);
      store.dispatch({ type: "ALL_EMP", payload: response.content });
    } catch (error) {
      console.error('Error fetching initial state:', error);
    }
  };
  

  const initializecoutUnitaire = async () => {
    try {
      const response = await api_cout.getCoutUnitaire();
      console.log(response.cunitaire);
      store.dispatch({ type: "UPDATE_COUT_UNITAIRE", payload: response.cunitaire });
    } catch (error) {
      console.error('Error fetching initial state:', error);
    }
  };
  


  
  const initializePointage = async () => {
    try {
      const response = await api_pointage.getAllPointage();
      console.log("pointage",response);
      store.dispatch({ type: "ALL_POINTAGE", payload: response.content });
    } catch (error) {
      console.error('Error fetching initial state:', error);
    }
  };
  
  // initializecoutUnitaire(); 
  // initializePointage(); 
  // initializeEmp(); 
  // initializeProd(); 
  




  const tokenExistsInCookies = () => {
    
    return Cookies.get('token') !== undefined;
  };
  

// Function to initialize the store if token exists in cookies
const initializeStoreIfTokenExists = async () => {
  if (!tokenExistsInCookies()) {
    setTimeout(initializeStoreIfTokenExists, 1000); // Check every second
    return;
  }

  // Token exists, initialize the store
  initializecoutUnitaire();
  initializePointage();
  initializeEmp();
  initializeProd();
};

// Start checking for token existence
initializeStoreIfTokenExists();
  export default store;
  