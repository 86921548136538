// import React, { useState, useEffect } from 'react';
// import { Button, Form, Input, Modal } from 'antd';
// import { useSelector, useDispatch } from 'react-redux';
// import api from '../api/coutUnitaire';
// import { Divider, Flex, Tag } from 'antd';
// import Notifiyer from '../notification/index';
// import { DollarOutlined } from '@ant-design/icons';
// const IndexsUnitPriceModel = () => {
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [coutUnitaire, setCoutUnitaire] = useState('');
//   const dispatch = useDispatch();
//   const currentCoutUnitaire = useSelector(state => state.coutUnitaire.coutUnitaire);

//   // // Fetch the value of coutUnitaire from the Redux store every time the modal opens
//   // useEffect(() => {
//   //   if (isModalOpen) {
//   //     const currentCoutUnitaire = useSelector(state => state.coutUnitaire);
//   //     setCoutUnitaire(currentCoutUnitaire);
//   //   }
//   // }, [isModalOpen]);

//   const showModal = () => {
//     setCoutUnitaire(''); 

//     setIsModalOpen(true);

//   };

//   const handleOk = () => {
//     setIsModalOpen(false);

//   };

//   const handleCancel = () => {
//     setIsModalOpen(false);

//   };
//   useEffect(() => {
//     if (!isModalOpen) {
//       setCoutUnitaire(''); // Reset the input value when the modal is closed
//     }
//   }, [isModalOpen]);
//   const onFinish = (values) => {
//     dispatch({ type: 'UPDATE_COUT_UNITAIRE', payload: values.coutUnitaire });
//     console.log('Success:', values);
//     api.upadateCoutUnitaire(values.coutUnitaire);
//     handleCancel();
//     Notifiyer();
//     };

//   const onFinishFailed = (errorInfo) => {
//     console.log('Failed:', errorInfo);
//   };

//   return (
//     <>
//       <Button type="primary" onClick={showModal}>
//       <DollarOutlined />  Coût Unitaire
//       </Button>
//       <Modal
//         title=""
//         visible={isModalOpen}
//         onOk={handleOk}
//         onCancel={handleCancel}
//         footer={null}
//         style={{ top: 20 }}
//       >

//         <Flex gap="10px 0" wrap="wrap">
//         <h2>Coût unitaire actuel = 
//         <Tag color="#108ee9"  style={{ fontSize: '20px' }}>{currentCoutUnitaire}</Tag>

//  </h2>
//     </Flex>
//         <Form
//           name="basic"
//           onFinish={onFinish}
//           onFinishFailed={onFinishFailed}
//           autoComplete="off"
//         >
//           <Form.Item
//             label="Coût unitaire"
//             name="coutUnitaire"
//             rules={[
//               {
//                 required: true,
//                 message: 'Please input the unit cost!',
//               },
//             ]}
//           >
//             <Input defaultValue={coutUnitaire} onChange={(e) => setCoutUnitaire(e.target.value)} />
//           </Form.Item>
//           <Form.Item>
//             <Button type="primary" htmlType="submit">
//             sauvegarder
//             </Button>
//           </Form.Item>
//         </Form>
//       </Modal>
//     </>
//   );
// };

// export default IndexsUnitPriceModel;
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Modal, Typography, Divider, Tag,Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import api from '../api/coutUnitaire';
import Notifiyer from '../notification/index';
import { DollarOutlined } from '@ant-design/icons';

const { Title } = Typography;

const IndexsUnitPriceModel = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [coutUnitaire, setCoutUnitaire] = useState('');
  const dispatch = useDispatch();
  const currentCoutUnitaire = useSelector(state => state.coutUnitaire.coutUnitaire);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();

  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();

  };

  useEffect(() => {
    if (!isModalOpen) {
      setCoutUnitaire('');
    }
  }, [isModalOpen]);

  const onFinish = (values) => {
    dispatch({ type: 'UPDATE_COUT_UNITAIRE', payload: values.coutUnitaire });
    api.upadateCoutUnitaire(values.coutUnitaire);
    handleCancel();
    Notifiyer();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        <DollarOutlined /> Coût Unitaire
      </Button>
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <Row justify="center">
        <Col span={24} style={{ textAlign: 'center', marginBottom: '16px' }}>
          <Title level={4}>Coût Unitaire Actuel</Title>
          <h1 style={{ display: 'inline-block', background: '#108ee9', padding: '8px', borderRadius: '8px', color: 'white', fontSize: '32px' }}>
            {currentCoutUnitaire}
          </h1>
        </Col>
        </Row>
        <Form
          name="basic"
          form={form}

          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Nouveau Coût Unitaire"
            name="coutUnitaire"
            rules={[
              {
                required: true,
                message: 'Veuillez saisir le nouveau coût unitaire!',
              }, {
                pattern: /^[0-9]+(?:\.[0-9]+)?$/, // Regular expression to accept integers or floats
                message: 'Please enter a valid number',
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Sauvegarder
            </Button>
            <Button onClick={handleCancel} style={{ marginLeft: '10px' }}>
              Annuler
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default IndexsUnitPriceModel;
