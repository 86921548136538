
import axios from 'axios';
import API_URl from  './const';
const endpoint = '/latest-indexes';
const endpoint_product_unit = '/productions/product-unit';


const updateLatestIndex = (formData) => {
    axios.post(API_URl + endpoint, formData)
      .then((response) => {
        console.log('Form data submitted successfully:', response.data);
        //update store here
      })
      .catch((error) => {
        console.error('Error submitting form data:', error);
      });
  };

  const addProductAndUnit = (product, unit) => {
    const url = `${API_URl}${endpoint_product_unit}?product=${product}&unit=${unit}`;
  
    axios.post(url)
      .then((response) => {
        console.log('Form data submitted successfully:', response.data);
        // Update store here
      })
      .catch((error) => {
        console.error('Error submitting form data:', error);
      });
  };
  

  
  

  export default {
    updateLatestIndex,
    addProductAndUnit
  }