import React, { useRef, useState, useEffect } from 'react';
import ProdDisplay from "./prodDisplay";
import { Row, Col } from 'antd';
import ProdEntryModel from "./prodEntryModel";
import StopProdModel from "./StopProdModel";
import ProdReportModel from './prodReport';
import moment from 'moment';
import RefreshButton from '../refresh';
import { useCookies } from 'react-cookie'; // Import useCookies hook

const ProdMain = () => {
  const [refreshDisplay, setRefreshDisplay] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(['token']); // Use useCookies hook

  const handleModelSubmit = () => {
    setRefreshDisplay(true);
  };

  return (
    <>
      <Row gutter={[16, 16]} justify="start">
      {cookies.username && !cookies.username.includes('viewer') && (

        <Col xs={24} sm={8} md={3}>
          <ProdEntryModel onSubmit={handleModelSubmit} isCreated={true} initialFormData={{
            codeEntite: "Usine_1",
            dateProduction: null,
            qteProduction: 0,
            codeProduit: "000001",
            codeCond: "000001",
          }} />
        </Col>
      )}
        {/* {cookies.username && !cookies.username.includes('viewer') && (

        <Col xs={24} sm={8} md={4}>
          <StopProdModel onSubmit={handleModelSubmit} />
       
        </Col>
      )} */}
        <Col xs={24} sm={8} md={3}>
          <ProdReportModel />
        </Col>
        {cookies.username && !cookies.username.includes('viewer') && (

        <Col xs={24} sm={8} md={3}>
          <RefreshButton handleModelSubmit={handleModelSubmit} />
        </Col>
        )} 
      </Row>
      <Row gutter={[16, 16]} justify="start">
        <Col xs={24} sm={24} md={24}>
          <ProdDisplay onSubmit={handleModelSubmit} refresh={refreshDisplay} onRefreshComplete={() => setRefreshDisplay(false)} />
        </Col>
      </Row>
    </>
  );
}

export default ProdMain;
