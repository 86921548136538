import React, { useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  NodeIndexOutlined,
  ProductOutlined,
  LogoutOutlined,
  SolutionOutlined,TeamOutlined,SettingOutlined
} from '@ant-design/icons';
import { useCookies } from 'react-cookie'; // Import useCookies hook

import { Layout, Menu, Button, theme } from 'antd';
import { IndexsMain } from './indexes';

import { ProdMain } from './production';
import { PointMain } from './pointage';

import { EmpMain } from './employee';
import AdminConf from './admin-conf';


import  Login  from './login';


const { Header, Sider, Content } = Layout;

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('nav1'); 
  const [cookies, setCookie, removeCookie] = useCookies(['token']); // Use useCookies hook
  // const handleMenuClick = (e) => {
  //   setSelectedMenu(e.key);
  // };

  const [selectedMenuName, setSelectedMenuName] = useState('Indexes');

  const handleMenuClick = (e) => {
    setSelectedMenu(e.key);
    switch (e.key) {
      case 'nav1':
        setSelectedMenuName('Indexes');
        break;
      case 'nav2':
        setSelectedMenuName('Production');
        break;
      case 'nav3':
        setSelectedMenuName('Pointage');
        break;
      case 'nav4':
        setSelectedMenuName('Ouvriers');
        break;
      case 'nav5':
        setSelectedMenuName('Configuration');  
        break;
      default:
        setSelectedMenuName('');
    }
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case 'nav1':
        return (
        <>
        <IndexsMain /> 
        
        </>
        );
        
       
      case 'nav2':
        return <ProdMain />
      case 'nav3':
        return <PointMain />;
      case 'nav4' :
         return <EmpMain />  ;
      case 'nav5' :
        //  return <AdminConf />  ;
         if (cookies.username && cookies.username.includes('admin')) {
          return <AdminConf />;
        } else {
          // Render some other component or null if the user is not admin
          return null;
        }
      default:
        return  <IndexsMain />; // Default to IndexsModel if no menu item is selected
    }
  };



  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const handleLogout = () => {
    // Delete token from cookies and reload
    removeCookie('token');
    removeCookie('username');

    window.location.reload();
  };
  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['nav1']}
          onClick={handleMenuClick}
        >
          <Menu.Item key="nav1" icon={ <NodeIndexOutlined />}>
          Indexes
          </Menu.Item>
          <Menu.Item key="nav2" icon={<ProductOutlined />}>
          Production 
          </Menu.Item>
          <Menu.Item key="nav3" icon={<SolutionOutlined />}>
          Pointage
          </Menu.Item>
          <Menu.Item key="nav4" icon={<TeamOutlined />}>
          Ouvriers
          </Menu.Item>
          {cookies.username && cookies.username.includes('admin') && (
      <Menu.Item key="nav5" icon={<SettingOutlined />}>
        Configuration
      </Menu.Item>
    )}


        </Menu>
      </Sider>
      <Layout>
        <Header
     

          style={{
            padding: 0,
            background: colorBgContainer,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
  <div style={{ flex: 1, textAlign: 'center', fontWeight: 'bold', fontSize: '32px', color: '#333' }}>{selectedMenuName}</div>  <div style={{ width: 64 }} /> 
  <div>
            <Button
              type="text"
              icon={<LogoutOutlined  style={{ }} />}
              onClick={handleLogout}

              style={{
                fontSize: '16px',
                width: 64,
                height: 64,
              }}
            />
          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 600,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          {renderContent()}
        </Content>
      </Layout>
    </Layout>
  );
};

export default App;
