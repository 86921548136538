import React, { useState ,useEffect } from 'react';

import { Collapse, Input, Button, Form,Col,Row } from 'antd';
import api from  '../api/indexdata';
import api_conf from  '../api/configapi';
import Notifiyer from '../notification/index';

const { Item } = Form;

const { Panel } = Collapse;
const AdminConf = () => {

  const [formData, setFormData] = useState([]);

    const text = `
    A dog is a type of domesticated animal.
    Known for its loyalty and faithfulness,
    it can be found as a welcome guest in many households across the world.
  `;
    const customPanelStyle = {
        background: '#f7f7f7',
        borderRadius: 4,
        marginBottom: 24,
        border: 0,
        overflow: 'hidden',
      };

      const handleSave = (panelKey) => {
        // Add your save logic here
        console.log(`Saving data from panel ${panelKey}`);
      };

      
      const fetchData = async () => {
        try {
          const result = await api.getLatestIndexes();
          console.log("Result from API conf :", result.data);
          setFormData(result.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
    
      useEffect(() => {
        fetchData();
      }, []);
    

const handleChange = (codeEntite, value) => {
// Create a copy of formData array
const updatedFormData = [...formData];

// Find the index of the item with the specified codeEntite
const index = updatedFormData.findIndex(item => item.codeEntite === codeEntite);

// If item is found, update its ancienIndex
if (index !== -1) {
  updatedFormData[index] = { ...updatedFormData[index], ancienIndex: value };
  setFormData(updatedFormData);
}

};

const submitIndex = () => {
  
  try {
    console.log(formData);
    api_conf.updateLatestIndex(formData);
    //setFormData([]]);
   
    // setTimeout(() => {
    // fetchData();
    // //onSubmit();
    // }, 1000);

     // closeModal(); // Close the modal

      Notifiyer();
  } catch (error) {
    console.error('Failed to submit form data:', error);
   // setErrorMessage('Failed to submit form data. Please try again.',error);

  }
  

};

const submitProduction = () => {
  
  try {
    console.log(formData);
    api_conf.addProductAndUnit(productValue,unitValue);
console.log("unitValue",unitValue)
console.log("productValue",productValue)

    //setFormData([]]);
   
    setTimeout(() => {
    setproductValue("");
    setunitValue("");
    //onSubmit();
    }, 1000);

     // closeModal(); // Close the modal

      Notifiyer();
  } catch (error) {
    console.error('Failed to submit form data:', error);
   // setErrorMessage('Failed to submit form data. Please try again.',error);

  }
  

};
const [productValue, setproductValue] = useState('');
const [unitValue, setunitValue] = useState('');

  const handleChangeProduct = (e) => {
    setproductValue(e.target.value);
  };

  const handleChangeUnit = (e) => {
    setunitValue(e.target.value);
  };
  return (
    <>
<Collapse bordered={true} defaultActiveKey={['1']}>
      {/* <Panel header="Réinitialiser les index" key="1" style={customPanelStyle}>

      <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
         <b>Broilleur (Divisionnaire)</b> 
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
        
          <Input placeholder="" 
            value={formData[0]?.ancienIndex}
            onChange={(e) => handleChange(formData[0]?.codeEntite, e.target.value)}
            />
          </Col>
      
        </Row>
        <br></br>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 6 }}>
         <b> Usine_1 & Usine_2 (Principal)</b>
          
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
          <Input placeholder="" 
            value={formData[1]?.ancienIndex}
            onChange={(e) => handleChange(formData[1]?.codeEntite, e.target.value)}
            />
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
            <Button type="primary" onClick={() => submitIndex()}>Sauvegarder</Button>
          </Col>
        </Row>
      </Panel> */}

<Panel header="Réinitialiser les index" key="1" style={customPanelStyle}>
  {formData.map((data, index) => (
    <React.Fragment key={index}>
      <Row gutter={[16, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 6 }}>
          <b>{data.codeEntite === 'Broilleur' ? 'Broilleur ( Divisionnaire )' : 'Usine_1 & Usine_2 ( Principal )'}</b>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 8 }}>
          <Input
            placeholder=""
            value={data.ancienIndex}
            onChange={(e) => handleChange(data.codeEntite, e.target.value)}
          />
        </Col>
        {data.codeEntite !== 'Broilleur' && (
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
            <Button type="primary" onClick={() => submitIndex()}>Sauvegarder</Button>
          </Col>
        )}
      </Row>
      <br />
    </React.Fragment>
  ))}
</Panel>

      <Panel header="Production" key="2" style={customPanelStyle}>
        
      <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 4 }}>
          <b>unité</b>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
        
          <Input placeholder="" 
            value={unitValue}
            onChange={handleChangeUnit}
            />
          </Col>
      
        </Row>
        <br></br>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 4 }}>
         <b>Produit</b> 
          
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
          <Input placeholder="" 
            value={productValue}
            onChange={handleChangeProduct}
            />
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 8 }}>
            <Button type="primary" onClick={() => submitProduction()}>Sauvegarder</Button>
          </Col>
        </Row>
      </Panel>

    </Collapse>
    </>
  );
}

export default AdminConf;
