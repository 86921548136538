import React, {useRef, useState,useEffect } from 'react';
import { Button, Space, Table,Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useSelector, useDispatch } from 'react-redux';
import api from  '../api/pointage';

const data = [];



const PointDisplay = ({ refresh, onRefreshComplete }) => {
  const [pointageList, setpointageList] = useState([]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});

  const fetchData = async () => {
    try {
      const result = await api.getAllPointage();
      const updatedData = result.content.map((item, index) => ({ ...item, key: index,
        present : item.present ? 'OUI' : 'NON'
       }));
      console.log("all pointage");
      console.log( updatedData);
      setpointageList( updatedData);
      onRefreshComplete();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    if (refresh) {
        fetchData();
    }
}, [refresh]);

  

const [searchText, setSearchText] = useState('');
const [searchedColumn, setSearchedColumn] = useState('');
const searchInput = useRef(null);
const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};
const handleReset = (clearFilters) => {
  clearFilters();
  setSearchText('');
};
const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
        >
          Filter
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});


  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'age',
    });
  };

  let columns = [
    {
      title: 'Nom Prenom',
      dataIndex: 'nomOuvriers',
      key: 'nomOuvriers',
      filters: [],
      filteredValue: filteredInfo.nomOuvriers || null,
      onFilter: (value, record) => record.nomOuvriers.includes(value),
      sorter: (a, b) => a.nomOuvriers.length - b.nomOuvriers.length,
      sortOrder: sortedInfo.columnKey === 'nomOuvriers' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('nomOuvriers'),
    },
    {
      title: 'identite',
      dataIndex: 'identite',
      key: 'identite',
      filteredValue: filteredInfo.identite || null,
      sorter: (a, b) => a.identite - b.identite,
      onFilter: (value, record) => record.identite.includes(value), // Use `value` instead of `identite`
      sortOrder: sortedInfo.columnKey === 'identite' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('identite'),
    },
    {
      title: 'present',
      dataIndex: 'present',
      key: 'present',
      filters: [
        { text: 'OUI', value: true },
        { text: 'NON', value: false }
      ],
      filteredValue: filteredInfo.present || null,
      sorter: (a, b) => {
        if (a.present === b.present) return 0;
        return a.present ? 1 : -1;
      },
      sortOrder: sortedInfo.columnKey === 'present' ? sortedInfo.order : null,
      ellipsis: false,
      ...getColumnSearchProps('present'),
      onFilter: (value, record) => record.present === value
    },    

    {
      title: 'Date',
      dataIndex: 'datePointage',
      key: 'datePointage',
      filters: [],
      filteredValue: filteredInfo.datePointage || null,
      sorter: (a, b) => new Date(a.datePointage) - new Date(b.datePointage),
      sortOrder: sortedInfo.columnKey === 'datePointage' ? sortedInfo.order : null,
      ellipsis: false,
      ...getColumnSearchProps('datePointage'),
      onFilter: (value, record) => record.datePointage.includes(value), // Correct filtering logic for Address


    },
  ];
  
//   const columns = [
//     {
//       title: 'Name',
//       dataIndex: 'name',
//       key: 'name',
//       filters: [
//         {
//           text: 'Joe',
//           value: 'Joe',
//         },
//         {
//           text: 'Jim',
//           value: 'Jim',
//         },
//       ],
//       filteredValue: filteredInfo.name || null,
//       onFilter: (value, record) => record.name.includes(value),
//       sorter: (a, b) => a.name.length - b.name.length,
//       sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
//       ellipsis: true,
//       ...getColumnSearchProps('name'),
//     },
//     {
//       title: 'Age',
//       dataIndex: 'age',
//       key: 'age',
//       filteredValue: filteredInfo.age || null,
//       sorter: (a, b) => a.age - b.age,
//       onFilter: (value, record) => record.age.includes(value),
//       sortOrder: sortedInfo.columnKey === 'age' ? sortedInfo.order : null,
//       ellipsis: true,
//       ...getColumnSearchProps('age'),
//     },
//     {
//       title: 'Address',
//       dataIndex: 'address',
//       key: 'address',
//       filters: [
//         {
//           text: 'London',
//           value: 'London',
//         },
//         {
//           text: 'New York',
//           value: 'New York',
//         },
//       ],
//       filteredValue: filteredInfo.address || null,
//       onFilter: (value, record) => record.address.includes(value),
//       sorter: (a, b) => a.address.length - b.address.length,
//       sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
//       ellipsis: true,
//       ...getColumnSearchProps('address'),
//     },
//   ];
  return (
    <>
      <Space
        style={{
         // marginBottom: 16,
        }}
      >
        {/* <Button onClick={setAgeSort}>Sort age</Button>
        <Button onClick={clearFilters}>Clear filters</Button>
        <Button onClick={clearAll}>Clear filters and sorters</Button> */}
      </Space>
      <Table  scroll={{ x: 700 }} columns={columns} dataSource={pointageList} onChange={handleChange} />
    </>
  );
};
export default PointDisplay;