import { notification } from 'antd';

// Inside your component function or class
const Notifiyer = () => {
  // Your form submission logic here

  // Example notification after form submission
  notification.success({
    message: 'Opération réussie',
    placement: 'bottomRight', // Adjust placement as needed
   
  });
};

export default Notifiyer;