// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import { Provider } from 'react-redux';
// import store from './store';
// import AxiosInterceptor from './login/interceptor'; // Import the interceptor component
// import { CookiesProvider, useCookies } from 'react-cookie'; // Import necessary functions from react-cookie
// import Login from './login';

// // Use createRoot instead of ReactDOM.render
// const root = ReactDOM.createRoot(document.getElementById('root'));

// // Wrap the root component with CookiesProvider
// root.render(
//   <React.StrictMode>
//     <CookiesProvider>
//       <AppWithAuthentication />
//     </CookiesProvider>
//   </React.StrictMode>
// );

// // Define a new functional component for authentication logic
// function AppWithAuthentication() {
//   const [cookies] = useCookies(['token']); // Retrieve the 'token' cookie
  
//   // Render either the App or Login component based on the presence of the 'token' cookie
//   return (
//     <Provider store={store}>
//       <AxiosInterceptor /> {/* Add the interceptor component */}
//       {cookies.token ? <App /> : <Login />} 
//     </Provider>
//   );
// }

// reportWebVitals();
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import AxiosInterceptor from './login/interceptor'; // Import the interceptor component
import { CookiesProvider, useCookies } from 'react-cookie'; // Import necessary functions from react-cookie
import Login from './login';
import axios from 'axios';

// Use createRoot instead of ReactDOM.render
const root = ReactDOM.createRoot(document.getElementById('root'));

// Wrap the root component with CookiesProvider
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <AppWithAuthentication />
    </CookiesProvider>
  </React.StrictMode>
);

// Define a new functional component for authentication logic
function AppWithAuthentication() {
  const [cookies] = useCookies(['token']); // Retrieve the 'token' cookie
  
  // Render either the App or Login component based on the presence of the 'token' cookie
  return (
    <Provider store={store}>
      <AxiosInterceptor /> {/* Add the interceptor component */}
      {cookies.token ? <App /> : <Login />} 
    </Provider>
  );
}

// Add a response interceptor to handle 401 status code
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // If a 401 status code is returned, redirect to the Login component
      root.render(
        <React.StrictMode>
          <CookiesProvider>
            <Login />
          </CookiesProvider>
        </React.StrictMode>
      );
    }
    return Promise.reject(error);
  }
);

reportWebVitals();
