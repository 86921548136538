// import React, { useState } from 'react';
// import {
//   PlusSquareOutlined,
//   PlusCircleOutlined,
//   EditOutlined
// } from '@ant-design/icons';


// import { Button, Form, Input, Select, DatePicker, Checkbox, Modal, Row, Col } from 'antd';
// import moment from 'moment';
// import api_emp from '../api/employee';
// import Notifiyer from '../notification/index';
// import dayjs from 'dayjs';
// const { TextArea } = Input;

// const  EmpEntryModel = ({ isCreated= true  ,onSubmit,initialFormData}) => {
   


//   const [submittedMessage, setsubmittedMessage] = useState('');



//   const [formData, setFormData] = useState(initialFormData);
//   const onChange = (value, name) => {
//     setFormData({ ...formData, [name]: value });
//   };
  
  
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const showModal = () => {
//     setIsModalOpen(true);
//   };
//   const handleOk = () => {
//     setIsModalOpen(false);
//   };
//   const handleCancel = () => {
//     setIsModalOpen(false);
//   };

//   const handleSubmit = () => {
//     console.log('Form values employee:',formData);

//     api_emp.addEmployee(formData);
  
//    // onSubmit();

//    setTimeout(() => {
//     resetForm(); // Reset the form data after submission
//     setFormData(initialFormData);
//    // resetForm();
//     Notifiyer();
//     handleCancel();
//     onSubmit();
//   }, 1000);
//   setTimeout(() => {
//     setsubmittedMessage('');
//   }, 3000);
//     // You can handle form submission here
//   };
//   const resetForm = () => {
//     setFormData(initialFormData);
//   };

  
// return (
//  <>
      



//        {isCreated ? (
//        <Button type="primary" onClick={showModal}>
//        < PlusSquareOutlined style={{ fontSize: '17px' }} /> Ajouter  
//        </Button>
//       ) : (
//         <Button type="primary" icon={<EditOutlined />} size="small" onClick={showModal}></Button>
//       )}


//        <Modal title="Ouvriers" 
//         open={isModalOpen}
//         onOk={handleOk} 
//         onCancel={handleCancel}
//         footer={null}
       
//        style={{ top: 20 }}
//        >
//       <Form 
//         layout='horizontal'
//         name="basic"
//         autoComplete="off"
//         onFinish={handleSubmit}
//         initialValues={{
//           name: initialFormData.nomPrenom, // Provide initial value for the 'name' field,
//           id : initialFormData.identite
//         }}
//       >
//               {submittedMessage && <p style={{ color: 'green' }}>{submittedMessage}</p>}

//         <Form.Item
//           label="Nom et Prénom:"
//           name="name"
//           rules={[
//             {
//               required: true,
//               message: 'Please type name ',
//             },
//           ]}
//         >
//           <Input    onChange={(e) => onChange(e.target.value, 'nomPrenom')} />
//         </Form.Item>

//         <Form.Item
//           label="Identité"
//           name="id"
//           rules={[
//             {
//               required: true,
//               message: 'Please type  Identity ',
//             },
//           ]}
//         >
//             <Input  onChange={(e) => onChange(e.target.value, 'identite')} />
//         </Form.Item>




//        <Form.Item label="Date de début du travail:">
//         <DatePicker   
//         defaultValue={dayjs(initialFormData.dateTravail)}
//         onChange={(date) => onChange(date, 'dateTravail')} style={{ width: '100%' }} />

//       </Form.Item>
 
     
//       <Form.Item label="Adresse:">
//             <TextArea rows={4} placeholder="" defaultValue={initialFormData.adresse} onChange={(e) => onChange(e.target.value, 'adresse')} />
//     </Form.Item>

      
//         <Form.Item>
//           <Button type="primary" htmlType="submit">
//           Sauvegarder
//           </Button>
//         </Form.Item>
//       </Form>
//        </Modal>
// </>

     
       
   
//     );
  
// }

// export default EmpEntryModel;
import React, { useState } from 'react';
import { PlusSquareOutlined, EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Form, Input, DatePicker, Modal, Row, Col,Select } from 'antd';
import dayjs from 'dayjs';
import api_emp from '../api/employee';
import Notifiyer from '../notification/index';

const { TextArea } = Input;

const EmpEntryModel = ({ isCreated = true, onSubmit, initialFormData }) => {
  const [submittedMessage, setsubmittedMessage] = useState('');
  const [formData, setFormData] = useState(initialFormData);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const onChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };

  const showModal = () => {
    setIsModalOpen(true);
    
  };

  const handleOk = () => {
    if (form.getFieldsError().filter(({ errors }) => errors.length).length === 0) {
      setIsModalOpen(false);
      form.resetFields();
    }

   // onSubmit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    resetForm();
    form.resetFields();
  //  onSubmit();
  };

  const handleSubmit = async () => {
    try {
      console.log(formData);
      await form.validateFields();
      await api_emp.addEmployee(formData);
      setsubmittedMessage('Employee added successfully!');
      resetForm();
      Notifiyer();
      onSubmit();
    } catch (error) {
      console.error('Error adding employee:', error);
    } finally {
      handleCancel();
    }
  };

  const resetForm = () => {
    setFormData({
      nomPrenom: '',
      identite: '',
      dateTravail: null,
      adresse: '',
      codeEntite:'Usine_1'
    });
  };

  return (
    <>
      {isCreated ? (
        <Button type="primary" onClick={showModal}>
          <PlusSquareOutlined style={{ fontSize: '17px' }} /> Ajouter
        </Button>
      ) : (
        <Button type="primary" icon={<EditOutlined />} size="small" onClick={showModal}></Button>
      )}

      <Modal
        title="Ouvriers"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        style={{ top: 20 }}
      >
        <Form
          layout="horizontal"
          name="basic"
          autoComplete="off"
          onFinish={handleSubmit}
          form={form}
          initialValues={{
            name: initialFormData.nomPrenom,
            id: initialFormData.identite,
            //dateTravail: initialFormData.dateTravail ? dayjs(initialFormData.dateTravail) : null,
            adresse: initialFormData.adresse,
            codeEntite: initialFormData.codeEntite
          }}
        >

          <Form.Item
            label="Nom et Prénom:"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please type name',
              },
            ]}
          >
            <Input onChange={(e) => onChange(e.target.value, 'nomPrenom')} />
          </Form.Item>

          <Form.Item
            label="Identité"
            name="id"
            rules={[
              {
                required: true,
                message: 'Please type Identity',
              },
            ]}
          >
            <Input onChange={(e) => onChange(e.target.value, 'identite')} />
          </Form.Item>

          <Form.Item label="Date de début du travail:">
            <DatePicker
             // defaultValue={initialFormData.dateTravail ? dayjs(initialFormData.dateTravail) : null}
             placeholder="Sélectionnez une date" 
             onChange={(date) => onChange( dayjs(date).format('YYYY-MM-DD'), 'dateTravail')}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Form.Item  label="Entite" name="codeEntite">
              <Select 
                defaultValue={formData.codeEntite}
                onChange={(value) => onChange(value,'codeEntite')}
              >
                <Select.Option value="Usine_1">Usine_1</Select.Option>
                <Select.Option value="Usine_2">Usine_2</Select.Option>
              </Select>
          </Form.Item>


          <Form.Item label="Adresse:" name="adresse">
            <TextArea
              rows={4}
              placeholder=""
              defaultValue={initialFormData.adresse}
              onChange={(e) => onChange(e.target.value, 'adresse')}
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Sauvegarder
            </Button>
            
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default EmpEntryModel;