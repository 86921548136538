// import React from 'react';
// import { Button, Space } from 'antd';
// import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
// import axios from 'axios'; // Assuming you're using axios for making API calls
// import API_URl from  '../api/const';
// import Notifiyer from '../notification/index';

// const UpdateDeleteButtons = ({ endpoint, record ,reloadData}) => {
//     let url =API_URl;

//     const getUrl = (endpoint) => {
//         let url = API_URl; // Initialize url with the base API URL
      
//         switch (endpoint) {
//           case "stopProd":
//             url += "/arret-prod";
//             break;
//           case "prod":
//             url += "/productions";
//             break;
//           // Add more cases for other endpoints if needed
//           case "index":
//             url += "/indexes";
//             break;
//             case "ouvriers":
//                 url += "/ouvriers";
//                 break;
//           default:
//             // Handle unknown endpoints or provide a default case
//             throw new Error(`Invalid endpoint: ${endpoint}`);
//         }
      
//         return url;
//       };
      
//   const handleUpdate = async () => {
//     try {
//       // Make update API call
//       const response = await axios.put(endpoint, record);
//       console.log(`Update record with endpoint: ${url}`, record);
//       console.log('Update done');
//       reloadData();
//     } catch (error) {
//       console.error('Update error:', error);
//     }
//   };

//   const handleDelete = async () => {
//     try {
//       // Make delete API call
//       const response = await axios.delete(getUrl(endpoint), { data: record });
//       console.log(`Delete record with endpoint: ${getUrl(endpoint)}`, record);
//       console.log('Delete done');
//       Notifiyer();
//       reloadData();

//     } catch (error) {
//       console.error('Delete error:', error);
//     }
//   };

//   return (
//     <Space size="middle">
//       {/* <Button type="primary" icon={<EditOutlined />} size="small" onClick={handleUpdate}></Button> */}
//       <Button type="danger" icon={<DeleteOutlined />} size="small" style={{ background: 'red', color:'white' }} onClick={() => handleDelete(record)}></Button>    </Space>
//   );
// };

// export default UpdateDeleteButtons;
import React, { useState } from 'react';
import { Button, Space, Modal } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios'; // Assuming you're using axios for making API calls
import API_URl from  '../api/const';
import Notifiyer from '../notification/index';

const UpdateDeleteButtons = ({ endpoint, record, reloadData ,isMainDelete=true}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const url = API_URl;

    const getUrl = (endpoint) => {
        let url = API_URl; // Initialize url with the base API URL
      
        switch (endpoint) {
          case "stopProd":
            url += "/arret-prod";
            break;
          case "prod":
            url += "/productions";
            break;
          // Add more cases for other endpoints if needed
          case "index":
            url += "/indexes";
            break;
          case "ouvriers":
            url += "/ouvriers";
            break;
          default:
            // Handle unknown endpoints or provide a default case
            throw new Error(`Invalid endpoint: ${endpoint}`);
        }
      
        return url;
      };

    const handleUpdate = async () => {
        try {
            // Make update API call
            const response = await axios.put(endpoint, record);
            console.log(`Update record with endpoint: ${url}`, record);
            console.log('Update done');
            reloadData();
        } catch (error) {
            console.error('Update error:', error);
        }
    };

    const handleDelete = async () => {
        try {
            // Make delete API call
            const response = await axios.delete(getUrl(endpoint), { data: record });
            console.log(`Delete record with endpoint: ${getUrl(endpoint)}`, record);
            console.log('Delete done');
            Notifiyer();
            reloadData();
            setIsModalVisible(false); // Close modal after deletion
        } catch (error) {
            console.error('Delete error:', error);
        }
    };

    return (
        <Space size="middle">
            {/* <Button type="primary" icon={<EditOutlined />} size="small" onClick={handleUpdate}></Button> */}
            {/* <Button
             
                ghost
                danger
                icon={<DeleteOutlined />}
                size="small"
               style={{ background: 'red', color: 'white' }}
                onClick={() => setIsModalVisible(true)}
            ></Button> */}

            {isMainDelete ? (
            <Button
                ghost
                danger
                icon={<DeleteOutlined />}
                size="small"
                style={{ background: 'red', color: 'white' }}
                onClick={() => setIsModalVisible(true)}
            ></Button>
            ) : (
            <Button
                ghost
                danger
                icon={<DeleteOutlined />}
                size="small"
                onClick={() => setIsModalVisible(true)}
            ></Button>
            )}

            <Modal
                title="Confirmer la suppression"
                visible={isModalVisible}
                onOk={handleDelete}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Annuler"
                okText="Supprimer"
                
            >
                <h2>Êtes-vous sûr de vouloir supprimer cet enregistrement ?</h2>
            </Modal>
        </Space>
    );
};

export default UpdateDeleteButtons;
