import React, {useRef, useState,useEffect } from 'react';
import { Button, Space, Table,Input,Col } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useSelector, useDispatch } from 'react-redux';
import api from  '../api/employee';
import moment from 'moment';
import EmpEntryModel from './employeeEntryModel';
import UpdateDeleteButtons from '../UpdateDeleteButtons';
import { useCookies } from 'react-cookie'; // Import useCookies hook


const EmpDisplay = ({ refresh, onRefreshComplete }) => {
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [cookies, setCookie, removeCookie] = useCookies(['token']); // Use useCookies hook


 // const data = useSelector(state => state.emp.content);
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const result = await api.getAllEmployee();
      const reversedContent = result.content.reverse(); // Reverse the content array
      const updatedData = reversedContent.map((item, index) => ({ ...item, key: item.id }));
      setData(updatedData);
      onRefreshComplete();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    if (refresh) {
         fetchData();
        console.log("fetchData -> :"+refresh)
    }
}, [refresh]);

const [searchText, setSearchText] = useState('');
const [searchedColumn, setSearchedColumn] = useState('');
const searchInput = useRef(null);
const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};
const handleReset = (clearFilters) => {
  clearFilters();
  setSearchText('');
};
const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
        >
          Filter
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});


  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'age',
    });
  };

  let columns = [
 {
      title: 'Nom & Prenom',
      dataIndex: 'nomPrenom',
      key: 'nomPrenom',
      filters: [],
      filteredValue: filteredInfo.nomPrenom || null,
      sorter: (a, b) => a.nomPrenom.length - b.nomPrenom.length,
      sortOrder: sortedInfo.columnKey === 'nomPrenom' ? sortedInfo.order : null,
      ellipsis: false,
      ...getColumnSearchProps('nomPrenom'),
      onFilter: (value, record) => record.nomPrenom.includes(value), // Correct filtering logic for Address

    },
    {
      title: 'dateTravail',
      dataIndex: 'dateTravail',
      key: 'dateTravail',
      filters: [],
      filteredValue: filteredInfo.dateTravail || null,
      onFilter: (value, record) => record.dateTravail.includes(value),
      sorter: (a, b) => new Date(a.dateTravail) - new Date(b.dateTravail),
      sortOrder: sortedInfo.columnKey === 'dateTravail' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('dateTravail'),
    }
    ,{
      title: 'Identite',
      dataIndex: 'identite',
      key: 'identite',
      filters: [],
      filteredValue: filteredInfo.identite || null,
      sorter: (a, b) => a.identite.length - b.identite.length,
      sortOrder: sortedInfo.columnKey === 'identite' ? sortedInfo.order : null,
      ellipsis: false,
      ...getColumnSearchProps('identite'),
      onFilter: (value, record) => record.identite.includes(value), // Correct filtering logic for Address


    },
    ,{
      title: 'Adresse',
      dataIndex: 'adresse',
      key: 'adresse',
      filters: [],
      filteredValue: filteredInfo.adresse || null,
      sorter: (a, b) => a.adresse.length - b.adresse.length,
      sortOrder: sortedInfo.columnKey === 'adresse' ? sortedInfo.order : null,
      ellipsis: false,
      ...getColumnSearchProps('adresse'),
      onFilter: (value, record) => record.adresse.includes(value), // Correct filtering logic for Address

    }
   
  ];
  if (cookies.username && !cookies.username.includes('viewer')) {
    columns.push(
      {
        title: 'Action',
        key: 'action',
        render: (text, record) =>
          record  ? (
            <Space size="middle">
       
                  <EmpEntryModel isCreated={false} initialFormData = {{
                    id:record.id,
                    nomPrenom: record.nomPrenom,
                    identite: record.identite,
                    dateTravail: moment(record.dateTravail),
                    adresse: record.adresse,
                    codeEntite:record.t_entites?.codeEntite || ''
  
                 }} 
                 record={record}
                 onSubmit={fetchData} />

            <UpdateDeleteButtons endpoint="ouvriers" record={record} reloadData={fetchData} />
  
  
            </Space>
          ) : null,
      }
    );
  }
  
//   const columns = [
//     {
//       title: 'Name',
//       dataIndex: 'name',
//       key: 'name',
//       filters: [
//         {
//           text: 'Joe',
//           value: 'Joe',
//         },
//         {
//           text: 'Jim',
//           value: 'Jim',
//         },
//       ],
//       filteredValue: filteredInfo.name || null,
//       onFilter: (value, record) => record.name.includes(value),
//       sorter: (a, b) => a.name.length - b.name.length,
//       sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
//       ellipsis: true,
//       ...getColumnSearchProps('name'),
//     },
//     {
//       title: 'Age',
//       dataIndex: 'age',
//       key: 'age',
//       filteredValue: filteredInfo.age || null,
//       sorter: (a, b) => a.age - b.age,
//       onFilter: (value, record) => record.age.includes(value),
//       sortOrder: sortedInfo.columnKey === 'age' ? sortedInfo.order : null,
//       ellipsis: true,
//       ...getColumnSearchProps('age'),
//     },
//     {
//       title: 'Address',
//       dataIndex: 'address',
//       key: 'address',
//       filters: [
//         {
//           text: 'London',
//           value: 'London',
//         },
//         {
//           text: 'New York',
//           value: 'New York',
//         },
//       ],
//       filteredValue: filteredInfo.address || null,
//       onFilter: (value, record) => record.address.includes(value),
//       sorter: (a, b) => a.address.length - b.address.length,
//       sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
//       ellipsis: true,
//       ...getColumnSearchProps('address'),
//     },
//   ];
  return (
    <>
      <Space
        style={{
         // marginBottom: 16,
        }}
      >
        {/* <Button onClick={setAgeSort}>Sort age</Button>
        <Button onClick={clearFilters}>Clear filters</Button>
        <Button onClick={clearAll}>Clear filters and sorters</Button> */}
      </Space>
      <Table scroll={{ x: 700 }} columns={columns} dataSource={data} 
      onChange={handleChange} 
      />
    </>
  );
};
export default EmpDisplay;