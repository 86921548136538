import axios from 'axios';
import { useCookies } from 'react-cookie';

const AxiosInterceptor = () => {
  const [cookies] = useCookies(['token']);

  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
        console.log("iterceptor --------------------------------")
        console.log(cookies.token);
      // Add token to headers for all requests except login
      if (!config.url.includes('login') && cookies.token) {
        config.headers.Authorization = `Bearer ${cookies.token}`; 
      }

    

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return null;
};

export default AxiosInterceptor;
