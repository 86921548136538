import React, { useState } from 'react';
import { DatePicker ,Button} from 'antd';
const { RangePicker } = DatePicker;

const IndexReport = () => {
    const [value, setValue] = useState(null);

    return (
        <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <RangePicker value={value}  onChange={setValue}  style={{ width: '80%' }} />
            <Button type="primary" >
       Export 
      </Button>
                </div>
            

        </>
    );
           

  };


export default IndexReport;

