
import { Row, Col } from 'antd';
import PointDisplay from './pointDispaly';
import PointEntryModel from './pointEntryModel';
import React, {useRef, useState,useEffect  } from 'react';
import PointReportModel from './pointReportModel';
import RefreshButton from '../refresh'
import { useCookies } from 'react-cookie'; // Import useCookies hook

const PointMain = ()=>{
  const [refreshDisplay, setRefreshDisplay] = useState(true);
  const [cookies, setCookie, removeCookie] = useCookies(['token']); // Use useCookies hook

  const handleModelSubmit = () => {
      setRefreshDisplay(true);
  };

    return (
        <>
      <Row gutter={[16, 16]} justify="start">
      {cookies.username && !cookies.username.includes('viewer') && (
      <Col xs={24} sm={8} md={3}>
        <PointEntryModel  onSubmit={handleModelSubmit} />
      </Col>
       )}
      <Col xs={24} sm={8} md={3} >
        <PointReportModel />
      </Col>
      {cookies.username && !cookies.username.includes('viewer') && (
      <Col xs={24} sm={8} md={3} >
      <RefreshButton handleModelSubmit ={handleModelSubmit} />
      </Col>
       )}
    </Row>
    <Row gutter={[16, 16]} justify="start">
      <Col xs={24} sm={24} md={24}>
        <PointDisplay  refresh={refreshDisplay} onRefreshComplete={() => setRefreshDisplay(false)} />
      </Col>
    </Row>
      
  </>
    );
}

export default PointMain;
