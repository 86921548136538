// import React, { useState } from 'react';
// import axios from 'axios';
// import { useCookies } from 'react-cookie';

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [error, setError] = useState('');
//   const [, setCookie] = useCookies(['token']);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('http://localhost:8080/api/auth/login', {
//         username,
//         password,
//       });
//       const { token } = response.data;
//       setCookie('token', token, { path: '/' }); // Save token in cookies
//       window.location.reload(); // Reload the page to render <App> component
//     } catch (error) {
//       setError('Invalid username or password');
//     }
//   };

//   return (
//     <div
//       style={{
//         backgroundColor: '#fff',
//         borderRadius: '10px',
//         boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
//         padding: '40px',
//         maxWidth: '400px',
//         width: '90%',
//         textAlign: 'center',
//         margin: '0 auto',
//       }}
//     >
//       <form
//         style={{
//           display: 'flex',
//           flexDirection: 'column',
//         }}
//         onSubmit={handleSubmit}
//       >
//         <h1>Bienvenue à nouveau</h1>
//         <p>Veuillez vous connecter à votre compte</p>
//         {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
//         <div
//           style={{
//             marginBottom: '20px',
//           }}
//         >
//           <input
//             style={{
//               padding: '15px',
//               borderRadius: '8px',
//               border: '1px solid #ddd',
//               width: '100%',
//               fontSize: '16px',
//               transition: 'border-color 0.3s ease',
//             }}
//             type="text"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//             placeholder="Username"
//             required
//           />
//         </div>
//         <div
//           style={{
//             marginBottom: '20px',
//           }}
//         >
//           <input
//             style={{
//               padding: '15px',
//               borderRadius: '8px',
//               border: '1px solid #ddd',
//               width: '100%',
//               fontSize: '16px',
//               transition: 'border-color 0.3s ease',
//             }}
//             type="password"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             placeholder="Password"
//             required
//           />
//         </div>
//         <button
//           style={{
//             padding: '15px',
//             border: 'none',
//             borderRadius: '8px',
//             backgroundColor: '#007bff',
//             color: '#fff',
//             fontSize: '16px',
//             cursor: 'pointer',
//             transition: 'background-color 0.3s ease',
//           }}
//           type="submit"
//         >
//           Login
//         </button>
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'column',
//             alignItems: 'center',
//             marginTop: '20px',
//             color: '#777',
//           }}
//         >
        
//         </div>
//       </form>
//     </div>
//   );
// };

// export default Login;

import React, { useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { Form, Input, Button, message } from 'antd';
import API_URl from '../api/const';

const Login = () => {
  const [error, setError] = useState('');
  const [, setCookie] = useCookies(['token']);

  const onFinish = async (values) => {
    try {
      const response = await axios.post(API_URl+'/auth/login', {
        username: values.username,
        password: values.password,
      });
      const { token,username } = response.data;
      setCookie('token', token, { path: '/' }); // Save token in cookies
      setCookie('username', username, { path: '/' }); // Save username in cookies

      window.location.reload(); // Reload the page to render <App> component
    } catch (error) {
      setError('Invalid username or password');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div style={{ backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: '10px',
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
          padding: '40px',
          maxWidth: '400px',
          width: '90%',
          textAlign: 'center',
        }}
      >
        {/* <img src="your-image-url.jpg" alt="Your Image" style={{ width: '100%', marginBottom: '20px' }} /> */}
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <h1>Bienvenue à nouveau</h1>
          <p>Veuillez vous connecter à votre compte</p>
          {error && <div style={{ color: 'red', marginBottom: '10px' }}>{error}</div>}
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input placeholder="Username" />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: '100%',
                borderRadius: '8px',
                transition: 'background-color 0.3s ease',
              }}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;

