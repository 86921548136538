
import axios from 'axios';
import API_URl from  './const';
const endpoint = '/indexes';

const addIndex = (formData) => {
    axios.post(API_URl + endpoint, formData)
      .then((response) => {
        console.log('Form data submitted successfully:', response.data);
      })
      .catch((error) => {
        console.error('Error submitting form data:', error);
      });
  };

  const getAllIndexs = (pageNumber=0, pageSize=1000000) => {
    return axios.get(`${API_URl + endpoint}?page=${pageNumber}&size=${pageSize}`)
      .then((response) => {
        console.log('Data fetched successfully: getAllIndexs ', response.data);
        return response.data; // Return data here
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        throw error; // Re-throw the error to maintain consistency
      });
  };

  const getLatestIndexes = () => {
   return   axios.get(API_URl + endpoint+"/latest")
      // .then((response) => {
      //   console.log('Form data submitted successfully:', response.data);
      // })
      // .catch((error) => {
      //   console.error('Error submitting form data:', error);
      // });
  };
  
  

  

  export default {
    addIndex,
    getAllIndexs,
    getLatestIndexes
  }