

import React, {useRef, useState,useEffect  } from 'react';
import { Button, Space, Table,Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import api from  '../api/indexdata';


import UpdateDeleteButtons from '../UpdateDeleteButtons';
import { useCookies } from 'react-cookie'; // Import useCookies hook


const IndexsDisplay = ({ refresh, onRefreshComplete }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['token']); // Use useCookies hook


  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const result = await api.getAllIndexs();
      console.log("fetchData")
      console.log(result);
      const updatedData = result.content.map((item, index) => ({ ...item, key: index }));
      setData(updatedData);
      onRefreshComplete();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  useEffect(() => {
    if (refresh) {
        fetchData();
    }
}, [refresh]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});


  

const [searchText, setSearchText] = useState('');
const [searchedColumn, setSearchedColumn] = useState('');
const searchInput = useRef(null);
const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};
const handleReset = (clearFilters) => {
  clearFilters();
  setSearchText('');
};
const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{
          marginBottom: 8,
          display: 'block',
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => clearFilters && handleReset(clearFilters)}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({
              closeDropdown: false,
            });
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          }}
        >
          Filter
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? '#1677ff' : undefined,
      }}
    />
  ),
  onFilter: (value, record) =>
    record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.current?.select(), 100);
    }
  },
  render: (text) =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{
          backgroundColor: '#ffc069',
          padding: 0,
        }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});


  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
    setCurrentPage(pagination.current); // Update current page when pagination changes

  };
  const clearFilters = () => {
    setFilteredInfo({});
  };
  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };
  const setAgeSort = () => {
    setSortedInfo({
      order: 'descend',
      columnKey: 'age',
    });
  };

  let columns = [
    {
      title: 'Entite',
      dataIndex: 'codeEntite',
      key: 'codeEntite',
      filteredValue: filteredInfo.codeEntite || null,
      sorter: (a, b) => a.codeEntite.localeCompare(b.codeEntite),
      sortOrder: sortedInfo.columnKey === 'codeEntite' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('codeEntite'),
    },
    {
      title: 'Date Du Jour',
      dataIndex: 'dateDuJour',
      key: 'dateDuJour',
      filteredValue: filteredInfo.dateDuJour || null,
      sorter: (a, b) => new Date(a.dateDuJour) - new Date(b.dateDuJour),
      sortOrder: sortedInfo.columnKey === 'dateDuJour' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('dateDuJour'),
    },
    {
      title: 'Index Du Jour',
      dataIndex: 'indexDuJour',
      key: 'indexDuJour',
      filteredValue: filteredInfo.indexDuJour || null,
      sorter: (a, b) => a.indexDuJour - b.indexDuJour,
      sortOrder: sortedInfo.columnKey === 'indexDuJour' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('indexDuJour'),
    },
    {
      title: 'Index précédent',
      dataIndex: 'previousIndexDuJour',
      key: 'previousIndexDuJour',
      filteredValue: filteredInfo.previousIndexDuJour || null,
      sorter: (a, b) => a.previousIndexDuJour - b.previousIndexDuJour,
      sortOrder: sortedInfo.columnKey === 'previousIndexDuJour' ? sortedInfo.order : null,
      ellipsis: true,
      ...getColumnSearchProps('previousIndexDuJour'),
    },
    
  ];

    // Add a new column for the difference
    columns.push({
      title: 'Consommation',
      dataIndex: 'difference',
      key: 'difference',
      render: (text, record) => record.indexDuJour - record.previousIndexDuJour,
    });

  if (cookies.username && !cookies.username.includes('viewer')) {
    columns.push(
   
      {
        title: 'Action',
        key: 'action',
        render: (text, record,index) =>
        ( currentPage == 1 && data[0].key == record.key)  ? (
            <Space size="middle">
              <UpdateDeleteButtons endpoint="index" record={record} reloadData={fetchData} />
            </Space>
          ) : null,
      },
    );
  }
  const [currentPage, setCurrentPage] = useState(1); // State to track current page
 
//   const columns = [
//     {
//       title: 'Name',
//       dataIndex: 'name',
//       key: 'name',
//       filters: [
//         {
//           text: 'Joe',
//           value: 'Joe',
//         },
//         {
//           text: 'Jim',
//           value: 'Jim',
//         },
//       ],
//       filteredValue: filteredInfo.name || null,
//       onFilter: (value, record) => record.name.includes(value),
//       sorter: (a, b) => a.name.length - b.name.length,
//       sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
//       ellipsis: true,
//       ...getColumnSearchProps('name'),
//     },
//     {
//       title: 'Age',
//       dataIndex: 'age',
//       key: 'age',
//       filteredValue: filteredInfo.age || null,
//       sorter: (a, b) => a.age - b.age,
//       onFilter: (value, record) => record.age.includes(value),
//       sortOrder: sortedInfo.columnKey === 'age' ? sortedInfo.order : null,
//       ellipsis: true,
//       ...getColumnSearchProps('age'),
//     },
//     {
//       title: 'Address',
//       dataIndex: 'address',
//       key: 'address',
//       filters: [
//         {
//           text: 'London',
//           value: 'London',
//         },
//         {
//           text: 'New York',
//           value: 'New York',
//         },
//       ],
//       filteredValue: filteredInfo.address || null,
//       onFilter: (value, record) => record.address.includes(value),
//       sorter: (a, b) => a.address.length - b.address.length,
//       sortOrder: sortedInfo.columnKey === 'address' ? sortedInfo.order : null,
//       ellipsis: true,
//       ...getColumnSearchProps('address'),
//     },
//   ];
  return (
    <>
      <Space
        style={{
         // marginBottom: 16,
        }}
      >
        {/* <Button onClick={setAgeSort}>Sort age</Button>
        <Button onClick={clearFilters}>Clear filters</Button>
        <Button onClick={clearAll}>Clear filters and sorters</Button> */}
      </Space>
      
      <Table scroll={{ x: 700 }}
       columns={columns} dataSource={data}
        onChange={handleChange}  
        pagination={{ pageSize: 20 }}/>
    </>
  );
};
export default IndexsDisplay;