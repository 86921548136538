import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import GeneralReport from '../reporting';


const ProdReportModel = () => {
 
  return (
    <>
     <GeneralReport endpoint="/productions"/>
    </>
  );
};
export default ProdReportModel;